.members-manager {
    display: flex;
    flex-direction: column;
}

.join-requests-card {
    background-color: #e8f5e9 !important; /* Light green background */
    margin-bottom: 0; /* Remove bottom margin */
}

.join-requests-card h3 {
    margin-top: 0;
    color: #2e7d32; /* Darker green for the title */
}

.join-request-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.join-request {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.request-info {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    position: relative; /* Add this */
}

.request-info::before {
    content: "";
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute; /* Change to absolute positioning */
    left: 0; /* Align to the left */
    top: 50%; /* Center vertically */
    transform: translateY(-40%); /* Move up slightly to adjust alignment */
}

.request-name-email {
    margin-right: 20px;
    margin-left: 60px; /* Add left margin to accommodate the image */
}

.request-institute {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    align-items: flex-start;
}

.request-institute small {
    font-size: 0.8em;
    color: #666;
    margin-bottom: 2px;
}

.request-buttons {
    display: flex;
    gap: 10px;
}

.approve-button,
.reject-button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.approve-button {
    background-color: var(
        --primary-color
    ); /* Primary color (you can adjust this) */
    color: white;
}

.reject-button {
    background-color: darkgrey !important;
    color: white;
}

.members-section {
    display: flex;
    flex-direction: column;
}

.members-section > div {
    margin: 20px;
}

/* New styles for the join request input and button */
.join-request-form {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #f0f0f0;
    border-radius: 0 0 4px 4px; /* Round only bottom corners */
    margin-top: -1px; /* Slightly overlap with the card above */
}

.join-request-input-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.join-request-input {
    flex-grow: 1;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    max-height: 35px; /* Decreased height from 100px to 60px */
    resize: vertical; /* Allow vertical resizing */
}

.email-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
}

.email-tag {
    background-color: #e0e0e0;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.email-tag-remove {
    margin-left: 5px;
    cursor: pointer;
    font-weight: bold;
}

.send-join-request-button {
    padding: 8px 16px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
}

.send-join-request-button:hover {
    background-color: var(--primary-color-dark);
}

/* New styles for member display */
.members-manager-content-members {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
    gap: 40px 20px !important;
    justify-content: center !important;
    max-width: 100% !important;
    margin-top: 20px !important;
}

.members-manager-content-member {
    display: flex !important;
    flex-direction: column !important;
}

.members-manager-content-member h4 {
    font-size: 18px !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    margin: 20px 0 10px !important;
}

.members-manager-content-member h5 {
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    color: #621f1f !important;
    margin: 0 0 10px !important;
}

.members-manager-content-member p {
    font-size: 10px !important;
    font-weight: 400 !important;
    letter-spacing: 0.5px !important;
    color: #787878 !important;
    line-height: 16px !important;
    margin: 0 !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
}

.members-manager-content-member-image {
    height: 200px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.members-manager-content-member-image img {
    width: 100% !important;
    height: 100% !important;
}

.members-manager-content-member-name {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-top: 5px !important;
}

.members-manager-content-members-section-wrapper h3 {
    font-size: 24px !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    padding-bottom: 15px !important;
    width: 100% !important;
    border-bottom: none !important; /* Remove the border from here */
}

/* New styles for Lab Members section */
.lab-members-section {
    margin-top: 20px;
}

.lab-members-list {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Reduced gap between cards */
    width: 100%;
}

.lab-members-list h4 {
    font-size: 18px !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    margin: 20px 0 10px;
}

.lab-member-card {
    width: 100%;
    transition: box-shadow 0.3s ease-in-out;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
}

.lab-member-card:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lab-member-remove-button-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    flex: 1;
}

.lab-member-item {
    border: 1px solid #e0e0e0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
    min-width: 0; /* Allow flex items to shrink below content size */
}

.lab-member-info {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    position: relative; /* Add this */
}

.lab-member-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #666;
    margin-right: 15px;
    cursor: pointer;
    overflow: hidden;
    flex-shrink: 0;
    position: absolute; /* Change to absolute positioning */
    left: 0; /* Align to the left */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Move up slightly to adjust alignment */
    z-index: 1; /* Ensure it stays above other content */
}

.lab-member-avatar:hover {
    transform: scale(1.05);
}

.lab-member-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lab-member-avatar:hover {
    cursor: pointer;
}

.lab-member-name-email {
    margin-right: 20px;
    margin-left: 60px; /* Add left margin to accommodate the image */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
}

.lab-member-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0; /* Prevent controls from shrinking */
}

.lab-member-permission-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 150px; /* Adjusted minimum width */
    max-width: 150px; /* Adjusted maximum width */
    overflow: hidden; /* Prevent overflow */
}

.lab-member-permission-label {
    font-size: 10px;
    font-weight: 500;
    color: #666;
    margin-bottom: 2px;
}

.lab-member-role-select {
    width: 100%; /* Ensure the dropdown takes full width of the wrapper */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.lab-member-remove-button {
    padding: 6px 12px;
    background-color: #f44336; /* Red color for remove button */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    height: 100%; /* Match parent height */
    display: flex;
    align-items: center;
    justify-content: center;
}

.lab-member-remove-button:hover {
    background-color: #d32f2f; /* Darker red on hover */
}

/* Adjust the existing styles to use the new class names */
.members-manager-content-members:not(.principal-investigator-section) {
    display: block !important;
}

.members-manager-content-member:not(.principal-investigator-member) {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    margin-bottom: 10px !important;
}

.members-manager-content-member:not(.principal-investigator-member) h4,
.members-manager-content-member:not(.principal-investigator-member) h5 {
    margin: 0 !important;
}

.members-manager-content-member:not(.principal-investigator-member)
    .members-manager-content-member-image {
    display: none !important;
}

/* New styles for the edit/save button */
.lab-members-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
}

.lab-members-title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0; /* Remove default margin */
}

.lab-members-edit-button,
.lab-members-save-button {
    width: 100px;
    padding: 6px 12px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    margin-bottom: 15px;
}

.lab-members-edit-button:hover,
.lab-members-save-button:hover {
    background-color: var(--primary-color-dark);
}

/* Styles for ing arrows */
.lab-member- {
    display: none;
    flex-direction: column;
    margin-left: 10px;
}

.lab-member-.visible {
    display: flex;
}

.lab-member-sort-arrow {
    cursor: pointer;
    font-size: 18px;
    color: #666;
    transition: color 0.3s ease;
}

.lab-member-sort-arrow:hover {
    color: var(--primary-color);
}

/* Adjust lab member card for sorting */
.lab-member-card.sorting {
    cursor: move;
}

.lab-member-item.sorting {
    justify-content: flex-start;
}

.lab-member-controls.sorting {
    margin-left: auto;
}

/* New styles for the assigned resources badge */
.assigned-resources-badge {
    font-size: 0.75rem;
    padding: 2px 6px;
    border-radius: 12px;
    white-space: nowrap;
}

.assigned-resources-badges {
    display: flex;
    gap: 8px;
    margin-left: 60px; /* Match the margin-left of name-email */
    flex-wrap: wrap;
    min-width: 0; /* Allow badges to wrap */
    flex: 1;
}

.assigned-resources-badge.items {
    background-color: #e3f2fd;
    color: #1565c0;
}

.assigned-resources-badge.equipments {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.assigned-resources-badge.services {
    background-color: #fff3e0;
    color: #e65100;
}

.members-manager-group-description h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.members-manager-group-description-content {
    font-size: 14px;
    line-height: 1.6;
    color: #555;
    white-space: pre-wrap;
}

.no-description {
    font-style: italic;
    color: #888;
}

/* Styles for group description in edit mode */
.members-manager-group-description-edit {
    width: 100%;
    height: 100%;
}

.members-manager-group-description-edit .MuiTextField-root {
    width: 100%;
}

.members-manager-group-description-edit .MuiInputBase-root {
    height: auto;
}

/* Remove outer border */
.members-manager-group-description-edit .MuiOutlinedInput-root {
    border: none;
    box-shadow: none;
}

/* Keep the inner border (notched outline) */
.members-manager-group-description-edit .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ccc;
}

/* Style hover and focus states */
.members-manager-group-description-edit
    .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #999;
}

.members-manager-group-description-edit
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color);
    border-width: 1px;
}

/* Style the textarea with reduced height */
.members-manager-group-description-edit textarea {
    min-height: 300px;
    height: auto;
    font-size: 14px;
    line-height: 1.6;
    resize: vertical;
    white-space: pre-wrap !important;
}

/* Adjust the group picture display */
.members-manager-group-picture-display {
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.members-manager-group-picture-display img {
    width: 100%;
    height: auto;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-picture {
    width: 100%;
    height: 200px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #888;
    border-radius: 8px;
}

/* Style for the group picture upload button */
.members-manager-group-picture-upload {
    margin-top: 10px;
    text-align: center;
}

.members-manager-group-picture-upload-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.members-manager-group-picture-upload-button:hover {
    background-color: var(--primary-color-dark);
}

/* New wrapper for group description and picture */
.members-manager-group-info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0;
    gap: 20px;
}

/* Adjust the group description styles */
.members-manager-group-description {
    flex: 1;
    margin-top: 0;
    max-width: 50%;
    width: 100%;
}

.members-manager-group-description h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
}

/* Styles for group description in view mode */
.members-manager-group-description-display {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    height: 100%;
}

.members-manager-group-description-content {
    font-size: 14px;
    line-height: 1.6;
    color: #555;
    white-space: pre-wrap !important;
}

.no-description {
    font-style: italic;
    color: #888;
}

/* Styles for group description in edit mode */
.members-manager-group-description-edit {
    width: 100%;
    height: 100%;
}

.members-manager-group-description-edit .MuiTextField-root {
    width: 100%;
}

.members-manager-group-description-edit .MuiInputBase-root {
    height: auto;
}

/* Remove outer border */
.members-manager-group-description-edit .MuiOutlinedInput-root {
    border: none;
    box-shadow: none;
}

/* Keep the inner border (notched outline) */
.members-manager-group-description-edit .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ccc;
}

/* Style hover and focus states */
.members-manager-group-description-edit
    .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #999;
}

.members-manager-group-description-edit
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color);
    border-width: 1px;
}

/* Style the textarea with reduced height */
.members-manager-group-description-edit textarea {
    min-height: 300px;
    height: auto;
    font-size: 14px;
    line-height: 1.6;
    resize: vertical;
    white-space: pre-wrap !important;
}

/* Adjust the group picture wrapper */
.members-manager-group-picture-wrapper {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
}

/* Style the group picture header */
.members-manager-group-picture-wrapper h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    align-self: flex-start; /* Align the header to the left */
    text-align: center;
    width: 100%;
}

/* Adjust the group picture display */
.members-manager-group-picture-display {
    width: 100%;
    margin: 0 0 10px 0; /* Add some bottom margin */
}

/* Style for the group picture upload button */
.members-manager-group-picture-upload {
    margin-top: 10px;
    display: flex;
    justify-content: center; /* Center the button horizontally */
}

.members-manager-group-picture-upload-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.members-manager-group-picture-upload-button:hover {
    background-color: var(--primary-color-dark);
}

/* Remove inner border of MUI TextField */
.members-manager-group-description-edit .MuiOutlinedInput-notchedOutline {
    border: none;
}

.members-manager-group-description-edit
    .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border: none;
}

.members-manager-group-description-edit
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border: none;
}

/* Optional: Add a custom border if desired */
.members-manager-group-description-edit .MuiOutlinedInput-root {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    min-height: 300px;
}

/* Optional: Style the focus state */
.members-manager-group-description-edit .MuiOutlinedInput-root.Mui-focused {
    border-color: var(--primary-color);
}

/* Add responsive layout adjustments */
@media screen and (max-width: 1024px) {
    /* Adjust group info wrapper for tablet/mobile */
    .members-manager-group-info-wrapper {
        flex-direction: column;
        gap: 30px;
    }

    .members-manager-group-description,
    .members-manager-group-picture-wrapper {
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    /* Adjust lab member item layout */
    .lab-member-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        padding: 15px;
        width: 100%;
    }

    .lab-member-info {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        min-height: 50px;
    }

    .lab-member-name-email {
        width: 100%;
        padding-left: 65px;
        margin-top: 0;
        margin-left: 0;
        display: flex;
        align-items: center;
        height: 50px;
    }

    .lab-member-avatar {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .assigned-resources-badges {
        margin-left: 0;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-top: 20px;
    }

    .lab-member-controls {
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .lab-member-permission-wrapper {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }

    /* Target MUI Select components with higher specificity */
    .lab-member-permission-wrapper .MuiFormControl-root,
    .lab-member-permission-wrapper .MuiInputBase-root,
    .lab-member-permission-wrapper .MuiOutlinedInput-root,
    .lab-member-permission-wrapper .MuiSelect-select,
    .lab-member-permission-wrapper .MuiSelect-outlined {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }

    /* Additional specificity for the select element itself */
    .lab-member-permission-wrapper select.MuiSelect-select {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }

    /* Adjust join request layout */
    .join-request {
        flex-direction: column;
        gap: 10px;
    }

    .request-info {
        flex-direction: column;
        align-items: flex-start;
    }

    .request-name-email {
        margin-left: 0;
        margin-top: 60px;
    }

    .request-buttons {
        width: 100%;
        justify-content: flex-start;
    }

    /* Adjust header layout */
    .listings-manager-filter {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .listings-manager-title {
        font-size: 20px;
    }

    /* Adjust remove button wrapper */
    .lab-member-remove-button-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
    }

    .lab-member-item {
        width: 100%;
    }

    .lab-member-remove-button {
        width: 100%;
        height: 40px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f44336;
        color: white;
        border-radius: 4px;
        transition: background-color 0.3s ease;
    }

    .lab-member-remove-button:hover {
        background-color: #d32f2f;
    }

    .lab-member-item.sorting {
        position: relative;
        padding-right: 50px;
        box-sizing: border-box;
        min-height: 140px;
    }

    .lab-member-sort.visible {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #f5f5f5;
        padding: 0;
        height: 100%;
        width: 45px;
    }

    .lab-member-sort-arrow {
        height: 50%;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-size: 24px;
        transition: background-color 0.2s ease;
    }

    .lab-member-sort-arrow:hover {
        background-color: #e0e0e0;
    }

    /* Add specific styles for top and bottom arrows */
    .lab-member-sort-arrow:first-child {
        border-top-right-radius: 4px;
    }

    .lab-member-sort-arrow:last-child {
        border-bottom-right-radius: 4px;
    }
}

@media screen and (max-width: 480px) {
    /* Further adjustments for very small screens */
    .members-manager {
        padding: 10px;
    }

    .assigned-resources-badges {
        flex-wrap: wrap;
    }

    .lab-member-name-email {
        font-size: 14px;
    }

    .members-manager-group-description-edit textarea {
        min-height: 200px;
    }

    /* Adjust card padding */
    .join-requests-card {
        padding: 10px;
    }

    /* Make buttons more touch-friendly */
    .approve-button,
    .reject-button,
    .lab-members-edit-button,
    .lab-members-save-button,
    .members-manager-group-picture-upload-button {
        padding: 10px 16px;
        min-height: 44px;
    }
}

.listings-manager-buttons {
    display: flex;
    gap: 10px;
}

.listings-manager-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.listings-manager-button.edit,
.listings-manager-button.save {
    background-color: var(--primary-color);
    color: white;
}

.listings-manager-button.cancel {
    background-color: #e0e0e0;
    color: #666;
}

.listings-manager-button:hover {
    opacity: 0.9;
}

@media screen and (max-width: 480px) {
    .listings-manager-buttons {
        width: 100%;
        justify-content: flex-end;
    }

    .listings-manager-button {
        padding: 10px 16px;
        min-height: 44px;
    }
}

/* Header styles */
.members-manager-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
}

.members-manager-title {
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.5px;
    color: #454545;
}

.members-manager-action-buttons {
    display: flex;
    gap: 10px;
}

.members-manager-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.members-manager-button.edit,
.members-manager-button.save {
    background-color: var(--primary-color);
    color: white;
}

.members-manager-button.cancel {
    background-color: #e0e0e0;
    color: #666;
}

.members-manager-button:hover {
    opacity: 0.9;
}

/* Responsive adjustments */
@media screen and (max-width: 480px) {
    .members-manager-action-buttons {
        width: 100%;
        justify-content: flex-end;
    }

    .members-manager-button {
        padding: 10px 16px;
        min-height: 44px;
    }
}

/* Update the sorting styles */
.lab-member-sort {
    display: none;
    flex-direction: column;
    gap: 4px;
    margin-left: 10px;
}

.lab-member-sort.visible {
    display: flex;
}

.lab-member-sort-arrow {
    cursor: pointer;
    font-size: 24px; /* Increased font size */
    color: #666;
    transition: color 0.3s ease;
    padding: 8px 12px; /* Increased padding */
    background-color: #f5f5f5;
    border-radius: 4px;
}

.lab-member-sort-arrow:hover {
    color: var(--primary-color);
    background-color: #e0e0e0;
}

@media screen and (max-width: 768px) {
    .lab-member-item.sorting {
        position: relative;
        padding-right: 50px;
        box-sizing: border-box;
        min-height: 140px; /* Increased minimum height */
    }

    .lab-member-sort.visible {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Spread items to full height */
        background-color: #f5f5f5;
        padding: 0; /* Remove padding */
        height: 100%;
        width: 45px;
    }

    .lab-member-sort-arrow {
        height: 50%; /* Make each arrow take half the height */
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-size: 24px;
        transition: background-color 0.2s ease;
    }

    .lab-member-sort-arrow:hover {
        background-color: #e0e0e0;
    }

    /* Add specific styles for top and bottom arrows */
    .lab-member-sort-arrow:first-child {
        border-top-right-radius: 4px;
    }

    .lab-member-sort-arrow:last-child {
        border-bottom-right-radius: 4px;
    }
}

/* Update media queries for better responsiveness */
@media screen and (max-width: 1200px) {
    .lab-member-permission-wrapper {
        min-width: 150px; /* Slightly smaller on medium screens */
        max-width: 150px;
    }
}

@media screen and (max-width: 992px) {
    .lab-member-permission-wrapper {
        min-width: 120px; /* Even smaller on smaller screens */
        max-width: 120px;
    }
}
