/* Import Listings Container Styles */
:root {
    --primary-color: #3f51b5;
    --primary-light: rgba(63, 81, 181, 0.1);
    --success-color: #4caf50;
    --danger-color: #f44336;
    --text-dark: #333;
    --text-medium: #555;
    --text-light: #777;
    --border-radius: 8px;
    --box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
}

.create-listing-header {
    text-align: center;
    color: var(--text-dark);
    margin-bottom: 30px;
    font-size: 2.2rem;
    font-weight: 500;
}

.import-container {
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 32px;
    margin: 30px auto;
    max-width: 800px;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.import-instructions {
    margin-bottom: 32px;
    text-align: center;
}

.import-instructions h2 {
    color: var(--text-dark);
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 500;
}

.import-instructions p {
    color: var(--text-medium);
    margin-bottom: 12px;
    line-height: 1.6;
    font-size: 1rem;
}

/* File Upload Styles */
.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 0;
}

.file-input {
    display: none;
}

.file-upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--primary-color);
    border-radius: var(--border-radius);
    padding: 50px 40px;
    width: 100%;
    max-width: 450px;
    cursor: pointer;
    transition: var(--transition);
    background-color: rgba(63, 81, 181, 0.03);
}

.file-upload-label:hover {
    background-color: var(--primary-light);
    transform: translateY(-2px);
}

.file-upload-label svg {
    font-size: 56px;
    color: var(--primary-color);
    margin-bottom: 20px;
    opacity: 0.9;
}

.file-upload-label span {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 1.1rem;
}

.selected-file {
    margin-top: 20px;
    padding: 12px 20px;
    background-color: #f5f7ff;
    border-radius: var(--border-radius);
    width: 100%;
    max-width: 450px;
    text-align: center;
    border: 1px solid rgba(63, 81, 181, 0.2);
}

.selected-file p {
    margin: 0;
    color: var(--text-medium);
    font-weight: 500;
}

.process-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 32px;
}

.process-file-button {
    background-color: var(--primary-color) !important;
    color: white !important;
    padding: 12px 28px !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    box-shadow: 0 3px 8px rgba(63, 81, 181, 0.3) !important;
    border-radius: 6px !important;
    transition: var(--transition) !important;
}

.process-file-button:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0 6px 12px rgba(63, 81, 181, 0.4) !important;
}

.error-message {
    margin-top: 24px;
    color: var(--danger-color);
    font-weight: 500;
    padding: 14px;
    background-color: rgba(244, 67, 54, 0.08);
    border-radius: var(--border-radius);
    text-align: center;
    border-left: 4px solid var(--danger-color);
}

/* Extraction Progress */
.extraction-progress {
    margin-top: 32px;
    width: 100%;
    max-width: 450px;
}

.extraction-progress h4 {
    margin-bottom: 12px;
    color: var(--text-medium);
    font-weight: 500;
}

.custom-progress-container {
    width: 100%;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    margin: 16px 0;
    overflow: hidden;
}

.custom-progress-bar {
    height: 100%;
    background-color: #4a1010;
    border-radius: 6px;
    transition: width 0.4s linear;
    animation: pulse 1.5s infinite;
}

/* Real-time extraction styles */
.extraction-in-progress {
    background-color: #f8f9ff;
    border: 1px solid rgba(63, 81, 181, 0.2);
    border-radius: var(--border-radius);
    padding: 20px;
    margin-bottom: 20px;
}

.extraction-status {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.extraction-status h3 {
    margin: 0 0 12px 0;
    color: var(--text-dark);
    font-weight: 500;
    font-size: 1.1rem;
}

.extraction-progress-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.stop-extraction-button {
    margin-left: 16px !important;
    color: var(--danger-color) !important;
    border-color: var(--danger-color) !important;
    font-weight: 500 !important;
    transition: background-color 0.3s ease, color 0.3s ease !important;
}

.stop-extraction-button:hover {
    background-color: rgba(244, 67, 54, 0.1) !important;
}

/* Review Listings Table Styles */
.review-listings-container {
    padding: 32px;
    margin: 30px auto;
    max-width: 1800px;
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.review-listings-container p {
    margin-bottom: 30px;
    color: var(--text-medium);
    font-size: 1.05rem;
    line-height: 1.6;
}

.listings-table {
    margin-bottom: 32px;
    overflow-x: auto;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.listings-table .MuiTableHead-root {
    background-color: #f5f7ff;
}

.listings-table .MuiTableHead-root .MuiTableCell-head {
    background-color: #f5f7ff;
    font-weight: 600;
    color: var(--text-dark);
    padding: 16px 12px;
    font-size: 0.95rem;
    border-bottom: 2px solid rgba(63, 81, 181, 0.2);
}

.listings-table .MuiTableBody-root .MuiTableCell-body {
    padding: 16px 12px;
    vertical-align: top;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.listings-table .MuiTableRow-root:hover {
    background-color: rgba(63, 81, 181, 0.03);
}

/* Column width adjustments */
.listings-table .MuiTableCell-root:nth-child(1) {
    width: 16%; /* Name column - increased from 12% */
}

.listings-table .MuiTableCell-root:nth-child(2) {
    width: 10%; /* Listing Type column */
}

.listings-table .MuiTableCell-root:nth-child(3) {
    width: 38%; /* Description column - increased to use space saved from other columns */
}

.listings-table .MuiTableCell-root:nth-child(4) {
    width: 12%; /* Lab Member column - reduced from 15% */
}

.listings-table .MuiTableCell-root:nth-child(5) {
    width: 10%; /* Licensing Type column */
}

.listings-table .MuiTableCell-root:nth-child(6) {
    width: 7%; /* Collaboration column - increased from 5% to fit header text */
}

.listings-table .MuiTableCell-root:nth-child(7) {
    width: 5%; /* Actions column - reduced from 7% */
}

/* Resizable columns styles */
.listings-table .MuiTableCell-head {
    position: relative;
}

.listings-table .resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px; /* Increased from 5px for easier targeting */
    height: 100%;
    background-color: rgba(63, 81, 181, 0.1);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    z-index: 10; /* Ensure the resizer is above other elements */
    opacity: 0;
    transition: opacity 0.2s;
    /* Center the resizer on the border */
    transform: translateX(4px);
}

.listings-table .MuiTableCell-head:hover .resizer {
    opacity: 1;
}

.listings-table .resizer.resizing,
.listings-table .resizer:hover {
    opacity: 1;
    background-color: rgba(
        63,
        81,
        181,
        0.6
    ); /* Brighter blue for better visibility */
}

/* Add a style for when a column is being resized */
.listings-table .MuiTableCell-head.resizing {
    background-color: rgba(
        63,
        81,
        181,
        0.05
    ); /* Subtle highlight for the column being resized */
}

.listings-table table {
    table-layout: fixed;
    width: 100%;
}

.listings-table .MuiTableCell-head,
.listings-table .MuiTableCell-body {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 50px; /* Prevents columns from getting too narrow */
}

.listings-table .MuiTableCell-body .table-textarea,
.listings-table .MuiTableCell-body .table-input,
.listings-table .MuiTableCell-body .table-select {
    width: 100%;
    box-sizing: border-box;
}

/* Make description column allow wrapping text */
.listings-table .MuiTableCell-root:nth-child(3) {
    white-space: normal;
}

.listings-table .MuiTableCell-root:nth-child(3) .table-textarea {
    white-space: normal;
}

.table-input,
.table-select,
.table-textarea {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: inherit;
    font-size: 0.9rem;
    transition: var(--transition);
}

.table-input:focus,
.table-select:focus,
.table-textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
}

.table-textarea {
    resize: vertical;
    min-height: 100px;
    line-height: 1.5;
}

.table-select-dropdown {
    min-width: 150px;
}

/* React Select dropdown styling to fix z-index issue */
.react-select__menu-portal {
    z-index: 9999 !important; /* Ensure dropdown is above all elements */
}

.react-select__control {
    border: 1px solid #ddd !important;
    border-radius: 4px !important;
    min-height: 38px !important;
    box-shadow: none !important;
    transition: var(--transition) !important;
}

.react-select__control:hover {
    border-color: var(--primary-color) !important;
}

.react-select__control--is-focused {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2) !important;
}

.react-select__value-container {
    padding: 2px 8px !important;
}

.listings-table .MuiTableCell-body {
    overflow: visible !important;
}

/* Make sure dropdown options are visible */
.listings-table .MuiTableCell-root:nth-child(2),
.listings-table .MuiTableCell-root:nth-child(4),
.listings-table .MuiTableCell-root:nth-child(5) {
    position: relative;
    overflow: visible !important;
}

/* Import Actions Styles */
.import-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    padding-top: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 900;
}

.import-actions > div[role="button"] {
    transition: var(--transition);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

.import-button,
div.import-button {
    background-color: var(--success-color) !important;
    color: white !important;
    padding: 12px 24px !important;
    font-weight: 500 !important;
    box-shadow: 0 3px 8px rgba(76, 175, 80, 0.3) !important;
    border-radius: 6px !important;
    transition: var(--transition) !important;
    cursor: pointer !important;
    position: relative !important;
    z-index: 600 !important;
}

.import-button:hover,
div.import-button:hover {
    background-color: #43a047 !important;
    box-shadow: 0 6px 12px rgba(76, 175, 80, 0.4) !important;
    transform: translateY(-2px);
}

.cancel-button,
div.cancel-button {
    border: none !important;
    background-color: #4a1010 !important;
    color: white !important;
    padding: 12px 24px !important;
    font-weight: 500 !important;
    transition: var(--transition) !important;
    cursor: pointer !important;
    position: relative !important;
    z-index: 600 !important;
}

.cancel-button:hover,
div.cancel-button:hover {
    background-color: #671616 !important;
    transform: translateY(-2px);
}

/* Animation for the progress bar */
@keyframes pulse {
    0% {
        opacity: 0.8;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.8;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .listings-table {
        overflow-x: auto;
    }

    .import-actions {
        flex-direction: column;
        gap: 16px;
    }

    .import-button,
    .cancel-button,
    div.import-button,
    div.cancel-button {
        width: 100%;
    }

    /* Make sure horizontal scroll works smoothly on mobile */
    .review-listings-container {
        max-width: 100%;
        padding: 16px;
    }

    /* Add a visual indicator that the table is scrollable */
    .listings-table::after {
        content: "← Scroll horizontally →";
        display: block;
        text-align: center;
        padding: 8px;
        color: var(--text-light);
        font-size: 0.8rem;
        background-color: rgba(63, 81, 181, 0.05);
        border-radius: 0 0 var(--border-radius) var(--border-radius);
    }

    /* Make the table take full width */
    .listings-table table {
        min-width: 1000px; /* Ensure all columns are visible with scrolling */
    }

    /* Improve touch targets for resizers on mobile */
    .listings-table .resizer {
        width: 12px;
        transform: translateX(6px);
    }

    /* Ensure column headers are visible and readable */
    .listings-table .MuiTableHead-root .MuiTableCell-head {
        white-space: normal;
        font-size: 0.85rem;
        padding: 12px 8px;
    }
}

/* Add dedicated styles for very small screens */
@media (max-width: 480px) {
    .review-listings-container p {
        font-size: 0.9rem;
    }

    .create-listing-header {
        font-size: 1.8rem;
    }
}

/* Add the missing progress bar styles back for backward compatibility */
.progress-bar {
    margin: 16px 0;
    height: 12px !important;
    border-radius: 6px !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.progress-bar-fill {
    background-color: #4a1010 !important;
    animation: pulse 1.5s infinite;
    transition: transform 0.4s linear !important;
}

/* Import tab styles */
.import-tabs {
    display: flex;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.import-tab {
    flex: 1;
    padding: 12px 20px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-medium);
    position: relative;
    transition: color 0.3s ease;
}

.import-tab.active {
    color: var(--primary-color);
}

.import-tab.active::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--primary-color);
}

.import-tab:hover {
    color: var(--primary-color);
}

/* Image upload styles */
.image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 0;
}

.image-preview-container {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-preview-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
}

.image-preview {
    max-width: 100%;
    max-height: 350px;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: block;
}

.remove-image-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: var(--danger-color);
    transition: all 0.2s ease;
}

.remove-image-button:hover {
    background-color: var(--danger-color);
    color: white;
}

.image-filename {
    margin-top: 8px;
    color: var(--text-medium);
    font-size: 14px;
}

.paste-image-container {
    margin-top: 24px;
    text-align: center;
}

.paste-image-container .paste-image-button {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    background-color: transparent !important;
    padding: 8px 16px !important;
    margin: 0 !important;
    box-shadow: none !important;
}

.paste-image-container .paste-image-button:hover {
    background-color: rgba(63, 81, 181, 0.05) !important;
}

.image-tip {
    margin-top: 12px;
    font-style: italic;
    color: var(--text-medium);
}

/* URL input styles */
.url-input-container {
    margin: 32px 0;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}

.url-input-field {
    width: 100%;
    position: relative;
}

.url-input {
    width: 100%;
    padding: 14px 16px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: var(--border-radius);
    transition: var(--transition);
}

.url-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(63, 81, 181, 0.1);
}

.selected-url {
    margin-top: 16px;
    padding: 12px 20px;
    background-color: #f5f7ff;
    border-radius: var(--border-radius);
    width: 100%;
    text-align: left;
    border: 1px solid rgba(63, 81, 181, 0.2);
}

.selected-url p {
    margin: 0;
    color: var(--text-medium);
    font-weight: 400;
    display: flex;
    align-items: center;
}

.import-info {
    margin-top: 12px;
    color: var(--text-light);
    text-align: center;
    font-size: 14px;
}

/* Responsive adjustments for the new components */
@media screen and (max-width: 768px) {
    .create-listing-header {
        font-size: 1.5rem;
    }

    .import-tab {
        padding: 10px 12px;
        font-size: 14px;
    }

    .url-input {
        padding: 12px;
    }

    .selected-url {
        padding: 10px 16px;
    }

    .import-tabs {
        flex-wrap: wrap;
    }

    .import-tab {
        min-width: 30%;
        text-align: center;
        padding: 8px 4px;
        font-size: 13px;
    }

    .image-preview {
        max-height: 250px;
    }

    .remove-image-button {
        width: 28px;
        height: 28px;
    }

    .paste-image-button {
        font-size: 14px;
        padding: 6px 12px !important;
    }

    .image-upload-container {
        margin: 20px 0;
    }

    .image-preview-container {
        width: 90%;
    }

    .image-tip {
        font-size: 13px;
        text-align: center;
        padding: 0 10px;
    }
}
