:root {
    --listing-height: 75vh;
    --listing-width: 150vh;
}

.lab-profile-content-listings {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 20px auto 40px;
}

.lab-profile-content-listings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px; /* Adjust gap as desired */
    max-width: 100%;
    justify-content: center;
    text-align: center;
    margin: 20px 0 0;
}

/** Listings Manager CSS **/
.lab-listings-container {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 0 30px;
}

.lab-listings-content {
    width: 90%;
    margin: 30px auto 0;
}

.lab-listings-search-banner {
    position: relative;
    width: 100%;
    height: 250px;
    background-color: var(--primary-color);
    overflow: hidden;
}

.lab-listings-search-banner-inputs {
    width: 100%;
}

.lab-listings-filter {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
}

.lab-listings-filter-time,
.lab-listings-filter-facets {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
    padding: 10px !important;
    border-radius: 5px !important;
    background-color: white !important;
    color: black !important;
    margin-left: 0 !important;
    margin-right: 10px !important;
    display: inline-flex !important;
    align-items: center !important;
    gap: 5px;
    height: 40px; /* Set a fixed height for the buttons */
}

.lab-listings-create-listing {
    position: absolute;
    display: inline-flex !important;
    right: 0;
    background-color: #fff !important; /* Match the color of lab-listings-filter-facets */
    color: black !important;
    border: none !important;
    margin-left: 30px !important; /* Space between the filter and time period buttons */
    cursor: pointer !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    outline: none !important;
    height: 40px !important; /* Set a fixed height for the buttons */
    align-items: center !important; /* Vertically center the content */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
    justify-content: center !important;
    gap: 5px;
}

.lab-listings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px; /* Adjust gap as desired */
    max-width: 100%;
    justify-content: center;
    text-align: center;
}

@media (max-width: 1100px) {
    .lab-listings-grid {
        grid-template-columns: repeat(
            auto-fill,
            minmax(350px, 1fr)
        ); /* Adjust to 3 columns on smaller screens */
        max-width: 1200px; /* Set maximum width to accommodate 3 columns */
    }
}

.lab-listings-listing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 400px;
    width: 100%; /* Full width of the grid column */
    background-color: white;
    height: auto; /* Height adjusts based on content */
}

.lab-listings-listing.browse-listings-listing {
    min-height: 375px !important;
}

.lab-listings-listing:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: transform 0.3s;
}

.lab-listings-listing:not(:hover) {
    transform: scale(1);
    transition: transform 0.3s;
}

.lab-listings-listing-img {
    width: 100%; /* Full width of the container */
    height: auto; /* Maintain aspect ratio */
    max-height: 200px; /* Limit height to keep uniformity */
    min-height: 200px; /* Ensure minimum height */
    object-fit: cover; /* Ensure the image covers the area well */
}

.lab-listings-listing-info {
    position: relative;
    text-align: center;
    flex: 1;
    margin-top: 20px;
    width: 100%;
}

.lab-listings-listing-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    font-size: 12px;
    display: inline-flex;
    flex: 1;
    letter-spacing: 0.5px;
    color: #454545;
    margin-top: 10px;
    justify-content: space-between; /* Add this to space out the badges */
}

.lab-listings-listing-collaborative {
    background-color: #e2e2e2;
    border-radius: 5px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    gap: 3px;
    font-size: 10px;
}

.lab-listings-listing-name {
    text-align: left;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    max-width: 275px; /* Adjust this value based on your specific layout needs */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lab-listings-listing-price {
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
}

.lab-listings-listing-description {
    color: #787878;
    letter-spacing: 0.5px;
    text-align: left;
    font-size: 12px;
    display: -webkit-box; /* Use flexbox for truncation */
    -webkit-box-orient: vertical; /* Set the box orientation to vertical */
    -webkit-line-clamp: 5; /* Limit to 6 lines */
    overflow: hidden; /* Hide overflow text */
}

.lab-listings-listing-badges {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
}

.lab-listings-listing-lister {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #ffd700;
    border-radius: 5px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
}

.lab-listings-listing-lister-name {
    max-width: 125px;
    white-space: nowrap;
    overflow: scroll;
    scrollbar-width: none;
}

.lab-listings-listing-type {
    background-color: #60d0fe;
    border-radius: 5px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    gap: 3px;
    font-size: 10px;
}

.lab-listings-listing-lister span {
    margin-left: 3px;
    font-size: 10px;
}

.lab-listings-listing-lister:hover {
    background-color: #e6c300;
    color: black;
    transition: 0.7s;
    cursor: pointer;
}

.lab-listings-listing-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.lab-listings-listing-buttons > button {
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 5px;
    align-items: center;
}

.lab-listings-listing-buttons > button:first-child {
    background-color: #ffd700;
}

.lab-listings-listing-buttons > button:nth-child(2) {
    background-color: #fa6363;
}

.filter-container {
    position: relative;
}

.filter-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.filter-dropdown {
    z-index: 100;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 300px; /* Adjust width as needed */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin-right: 15px;
    left: -100%; /* Initially position the component outside the viewport */
    animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
    from {
        transform: translateX(-25%); /* Start position outside the viewport */
        opacity: 0; /* Start with opacity 0 */
    }
    to {
        transform: translateX(0); /* End position at left: 0 */
        opacity: 1; /* Fully visible */
    }
}

.facet {
    margin-bottom: 10px;
}

.facet-title {
    width: 300px;
    font-weight: bold;
    text-align: left;
    font-size: 20px;
    padding: 20px 20px 0 20px;
    display: flex;
}

.facet-title:hover {
    cursor: pointer;
}

.facet-title-arrow {
    margin-left: auto;
    transition: transform 0.3s ease-in-out;
}

.facet-title-arrow.up {
    transform: rotate(180deg);
}

.facet-options {
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    overflow: hidden;
    max-height: 500;
    opacity: 1;
}

.facet-options {
    width: 100%;
    padding: 0 20px;
    text-align: left;
    border-radius: 3px;
    outline: none;
}

.facet-option {
    display: flex;
    align-items: center;
    gap: 10px;
}

.facet-option-checkbox {
    width: 20px;
    height: 20px;
}

.time-period-button {
    background-color: #fff !important; /* Match the color of lab-listings-filter-facets */
    color: black !important;
    border: none !important;
    margin-left: 10px !important; /* Space between the filter and time period buttons */
    cursor: pointer !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    outline: none !important;
    width: 100% !important;
    height: 40px !important; /* Set a fixed height for the buttons */
    display: flex !important; /* Use flexbox for alignment */
    align-items: center !important; /* Vertically center the content */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
    justify-content: center !important;
    gap: 5px;
}

.time-period-dropdown {
    position: relative !important;
    text-align: center !important;
}

.time-period-list {
    position: absolute !important;
    top: 100% !important; /* Position directly below the button */
    left: 0 !important;
    margin-left: 10px !important; /* Ensure no margin on the left */
    margin-top: 0 !important; /* Ensure no margin on the top */
    background-color: #fff !important; /* Match this with your filter-dropdown background */
    border: 1px solid #ddd !important; /* Example border, match with your actual dropdown */
    border-radius: 5px !important;
    width: 100% !important; /* Adjust width to match the button */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
    z-index: 1000 !important;
}

.time-period-item {
    padding: 8px 12px !important;
    cursor: pointer !important;
    text-align: center !important;
    list-style-type: none !important;
}

.time-period-item:hover {
    background-color: #f8f8f8 !important;
}
.lab-listings-listing-badges svg {
    cursor: pointer;
}

.facet-options ul {
    list-style-type: none;
    padding: 0;
}

.facet-option {
    padding: 5px 0;
}
