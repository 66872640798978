/*** Admin Dashboard ***/

.admin-container {
    display: flex;
    flex-direction: column;
}

.admin-tabs-container {
    display: flex;
    border-bottom: 1px solid #ccc;
}

/*** Admin Lab Approvals ***/

.lab-registration-container {
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    justify-content: flex-start;
}

.lab-registration-component {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.lab-registration-component:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

.lab-registration-component > h2 {
    flex: 1;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.lab-registration-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

.lab-registration-tags {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}

.lab-registration-actions {
    display: flex;
    gap: 10px;
}

.lab-registration-actions > button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 500;
}

/*** Admin Labs ***/

.admin-labs {
    display: flex;
}

.admin-labs-container {
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    justify-content: flex-start;
    width: 400px;
    height: calc(100vh - (64px + 50px));
    overflow-y: scroll;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-labs-section {
    margin-bottom: 20px;
}

.admin-labs-section-header {
    padding: 15px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.admin-labs-section-header:hover {
    background-color: #f9f9f9;
}

.admin-labs-section-header h1 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    display: flex;
    align-items: center;
}

.section-expand-icon {
    transition: transform 0.3s ease;
}

.section-expand-icon.expanded {
    transform: rotate(0deg);
}

.section-expand-icon.collapsed {
    transform: rotate(-90deg);
}

.admin-labs-department {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin-bottom: 5px;
}

.admin-labs-department-header {
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.admin-labs-department-header:hover {
    background-color: #e0e0e0;
}

.admin-labs-department-header h2 {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 8px;
}

.department-expand-icon {
    margin-left: auto;
    font-size: 20px !important;
    transition: transform 0.3s ease;
}

.department-expand-icon.expanded {
    transform: rotate(0deg);
}

.department-expand-icon.collapsed {
    transform: rotate(-90deg);
}

.admin-labs-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 10px 5px 20px;
}

.admin-labs-component {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    margin-bottom: 5px;
}

.admin-labs-component:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
}

.admin-labs-component.temporary {
    border-left: 4px solid #ff9800;
}

.admin-labs-component.approved {
    border-left: 4px solid #4caf50;
}

.admin-labs-component > h2 {
    flex: 1;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.lab-status {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: 500;
}

.lab-status.pending {
    background-color: #fff3e0;
    color: #e65100;
}

.lab-status.approved {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.admin-labs-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    color: #757575;
    font-style: italic;
}

.admin-lab-manager-container {
    flex: 1;
    border: 1px solid #ccc;
    height: calc(100vh - (64px + 50px));
    display: flex;
}

.admin-lab-manager-tabs {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ccc;
    width: 200px;
}

.admin-lab-manager-tabs > div {
    padding: 10px;
}

.admin-lab-manager-tabs > div:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

.admin-lab-manager-content {
    flex: 1;
    overflow-y: scroll;
}

/*** Admin Lab Overview ***/

.admin-lab-overview-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/*** Admin Lab Transfer ***/
.admin-lab-transfer {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
}

.admin-lab-transfer-header {
    margin-bottom: 2rem;
    text-align: center;
}

.admin-lab-transfer-header h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.admin-lab-transfer-header p {
    color: #666;
    font-size: 0.875rem;
}

.admin-lab-transfer-form {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.admin-lab-transfer-search-box {
    margin-bottom: 1rem;
}

.admin-lab-transfer-search-box label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.admin-lab-transfer-search-input-wrapper {
    position: relative;
}

.admin-lab-transfer-search-input-wrapper input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
}

.admin-lab-transfer-search-input-wrapper input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.admin-lab-transfer-clear-search {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #666;
    font-size: 1.25rem;
    cursor: pointer;
    padding: 0.25rem;
}

.admin-lab-transfer-search-results {
    margin-top: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    max-height: 300px;
    overflow-y: auto;
}

.admin-lab-transfer-user-item {
    width: 100%;
    padding: 0.75rem;
    background: none;
    border: none;
    border-bottom: 1px solid #e2e8f0;
    text-align: left;
    cursor: pointer;
}

.admin-lab-transfer-user-item:last-child {
    border-bottom: none;
}

.admin-lab-transfer-user-item:hover {
    background-color: #f8fafc;
}

.admin-lab-transfer-user-item.selected {
    background-color: #f0f9ff;
}

.admin-lab-transfer-user-item-content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.admin-lab-transfer-user-info-primary {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-lab-transfer-user-name {
    font-weight: 500;
}

.admin-lab-transfer-user-role {
    font-size: 0.75rem;
    color: #666;
    background: #f1f5f9;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
}

.admin-lab-transfer-user-email {
    font-size: 0.875rem;
    color: #666;
}

.admin-lab-transfer-no-results {
    padding: 1rem;
    text-align: center;
    color: #666;
}

.admin-lab-transfer-selected-user {
    margin-top: 1.5rem;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 6px;
}

.admin-lab-transfer-selected-user-info {
    margin-bottom: 1rem;
}

.admin-lab-transfer-selected-user-details h3 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 0.25rem 0;
    cursor: pointer;
}

.admin-lab-transfer-selected-user-details h3:hover {
    text-decoration: underline;
}

.admin-lab-transfer-selected-user-details p {
    font-size: 0.875rem;
    color: #666;
    margin: 0 0 0.5rem 0;
}

.admin-lab-transfer-role-badge {
    font-size: 0.75rem;
    background: #e2e8f0;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
}

.admin-lab-transfer-button {
    width: 100%;
    padding: 0.75rem;
    background: #3b82f6;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.admin-lab-transfer-button:hover {
    background: #2563eb;
}

.admin-lab-transfer-confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.admin-lab-transfer-confirmation-modal-content {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
}

.admin-lab-transfer-confirmation-modal-content h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.admin-lab-transfer-confirmation-modal-content p {
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

.admin-lab-transfer-confirmation-modal-actions {
    display: flex;
    gap: 0.75rem;
    justify-content: flex-end;
}

.admin-lab-transfer-confirmation-modal-cancel-button,
.admin-lab-transfer-confirmation-modal-confirm-button {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
}

.admin-lab-transfer-confirmation-modal-cancel-button {
    background: white;
    border: 1px solid #e2e8f0;
}

.admin-lab-transfer-confirmation-modal-cancel-button:hover {
    background: #f8fafc;
}

.admin-lab-transfer-confirmation-modal-confirm-button {
    background: #ef4444;
    color: white;
    border: none;
}

.admin-lab-transfer-confirmation-modal-confirm-button:hover {
    background: #dc2626;
}

.admin-lab-transfer-confirmation-modal-confirm-button:disabled,
.admin-lab-transfer-confirmation-modal-cancel-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (max-width: 640px) {
    .admin-lab-transfer {
        padding: 1rem;
    }

    .admin-lab-transfer-confirmation-modal-actions {
        flex-direction: column;
    }

    .admin-lab-transfer-confirmation-modal-actions button {
        width: 100%;
    }
}

/* User Feedback Styles */
.user-feedback-container {
    padding: 2rem;
    max-width: 100%;
}

.user-feedback-title {
    margin-bottom: 1rem !important;
    font-weight: 600 !important;
    color: #333;
}

.user-feedback-description {
    margin-bottom: 2rem !important;
    color: #666;
}

.user-feedback-table {
    margin-top: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    overflow: hidden;
}

.user-feedback-table th {
    background-color: #f5f5f5;
    font-weight: 600;
    color: #333;
}

.user-feedback-row:hover {
    background-color: #f9f9f9;
}

.user-feedback-user {
    display: flex;
    flex-direction: column;
}

.user-feedback-message {
    white-space: pre-wrap;
    word-break: break-word;
    padding: 1rem !important;
}

.user-feedback-date {
    font-size: 0.85rem;
    color: #666;
}

.user-feedback-source {
    text-transform: capitalize;
    font-size: 0.85rem;
}

.user-feedback-empty {
    padding: 2rem;
    text-align: center;
    color: #666;
    background: #f9f9f9;
    border-radius: 8px;
    margin-top: 1rem;
}

.user-feedback-loading,
.user-feedback-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
    gap: 1rem;
}

@media (max-width: 768px) {
    .user-feedback-container {
        padding: 1rem;
    }

    .user-feedback-table {
        overflow-x: auto;
    }
}
