:root {
    --center-width: 1200px;
    --window-threshold: 987px;
    --font-family: "poppins", Helvetica, Arial, sans-serif;
    --primary-color-dark: #333;
}

/* Add these rules at the root level */
html,
body {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.home-container {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scroll */
    position: relative;
    margin: 0;
    padding: 0;
}

.hero-image {
    position: fixed;
    top: 10px;
    left: 0;
    width: 100%;
    height: 70vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
        url("/src/assets/lab-giant-banner-adjusted.png");
    background-position: top 1% right;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.hero-text {
    text-align: left;
    color: white;
    font-family: var(--font-family);
    width: 100%;
    max-width: var(--center-width);
    padding: 0 5%;
    margin-top: 9vh !important;
}

.hero-text h1 {
    font-size: 48px !important;
    margin-bottom: 4vh !important;
    word-wrap: break-word !important; /* Allow long words to break and wrap */
    overflow-wrap: break-word !important; /* Alternative property for better browser support */
}

.hero-text h2 {
    font-size: 22px !important;
}

.hero-image-button {
    background-color: var(--primary-color) !important;
    font-family: var(--font-family) !important;
    text-transform: none !important;
    font-size: medium !important;
}

h1 {
    font-weight: 700;
    letter-spacing: -1px;
}

h2 {
    font-weight: 400;
}

.landingPageSection {
    background-color: white;
    position: relative;
    z-index: 1;
    padding-top: 40px;
    margin: auto;
}

.divider {
    background: var(--primary-color);
    content: "";
    display: block;
    width: 300px;
    height: 6px;
    position: absolute; /* Change from relative to absolute */
    z-index: 1; /* Ensure it appears on top */
    bottom: 0; /* Adjust as needed */
    left: 50%; /* Adjust as needed */
    transform: translateX(-50%);
}

.landingPageTitleText {
    color: rgb(51, 50, 50);
    font-family: var(--font-family);
    font-weight: 600;
}

.landingPageTitleText h3 {
    margin: 0;
}

@media (min-width: 987px) {
    .hero-text {
        width: 100%;
        max-width: var(--center-width);
        margin: 0 auto;
    }
    h1 {
        font-size: 64px;
    }
    .landingPageTitleText {
        text-align: left;
        font-size: 36px;
    }
    .howItWorks {
        flex-direction: row;
    }
}

@media (max-width: 986px) {
    .hero-image {
        height: 100vh; /* Increase from 60vh to cover full viewport */
    }

    .hero-text {
        padding: 0 20px; /* More consistent padding */
        margin: 15vh 0 5vh; /* Increase top margin for better spacing */
    }

    .hero-text h1 {
        font-size: 36px; /* Slightly larger for better readability */
        line-height: 1.3;
        margin-bottom: 20px !important;
    }

    .hero-text h2 {
        font-size: 18px !important; /* Slightly smaller for better hierarchy */
        line-height: 1.5;
    }

    .signup-section {
        margin-top: 15vh; /* Adjust spacing */
        padding: 0 20px 40px;
        width: 100%;
        box-sizing: border-box;
    }

    .signup-hero-container {
        width: 100%; /* Take full width */
        margin: 0 auto 30px; /* Center and add bottom margin */
        top: 0;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
    }

    .signup-cta {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        height: auto; /* Allow content to determine height */
        min-height: 200px;
    }

    .howItWorksSteps {
        padding: 20px;
        margin-bottom: 15px;
    }

    .howItWorksSteps_Title {
        font-size: 18px; /* Smaller for mobile */
    }

    .howItWorksSteps p {
        font-size: 14px;
        line-height: 1.5;
    }

    .landingPageSection {
        margin: 0 5vw 0 5vw;
    }
    .landingPageTitleText {
        font-size: 24px;
        line-height: 30px;
    }
    .howItWorks {
        flex-direction: column;
    }
    p {
        font-size: 13px;
    }
    .step-arrow {
        left: 50% !important;
        top: -23px; /* Adjusted from -25px to -20px */
        transform: translateX(-50%) rotate(90deg); /* Adjusted from -90deg to 180deg */
    }
}

/* Add new styles for better mobile spacing */
@media (max-width: 480px) {
    .hero-text h1 {
        font-size: 32px; /* Even smaller for very small screens */
    }

    .signup-cta-steps li {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .create-lab-link {
        font-size: 16px;
    }
}

.howItWorks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.howItWorksSteps {
    width: calc(33.33% - 18px);
    background-color: white;
    border-radius: 8px;
    padding: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 20px;
}

.howItWorksSteps:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.howItWorksSteps_Icon {
    font-size: 43px;
    color: var(--primary-color);
    margin-bottom: 20px;
}

.howItWorksSteps_Title {
    font-size: 22px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 15px;
}

.howItWorksSteps p {
    font-size: 15px;
    line-height: 1.6;
    color: #4a4a4a;
}

@media (max-width: 986px) {
    .howItWorksSteps {
        width: 100%;
        margin-bottom: 20px;
    }
}

.howItWorksContent {
    max-width: var(--center-width);
    margin: 0 auto;
    padding: 0 5%;
}

.signup-section {
    background-color: white;
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    align-items: flex-end; /* Change this from flex-start to flex-end */
    justify-content: center;
    margin-top: 17vh;
    height: auto; /* Change from fixed height to auto */
}

.signup-content {
    display: flex;
    width: 100%;
    max-width: var(--center-width);
    justify-content: flex-start; /* Change from space-between to flex-start */
    padding: 0 5%;
    position: relative;
    align-items: flex-end;
}

.signup-hero-container {
    background-color: white;
    padding: 10px;
    width: 60%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    top: -81px;
    margin-right: 5%;
    min-height: 405px;
    display: flex;
    flex-direction: column;
}

.signup-cta {
    width: calc(100% - 400px);
    color: var(--primary-color-dark);
    font-family: var(--font-family);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 5%;
    margin-bottom: 12%;
    transition: opacity 0.3s ease, transform 0.3s ease;
    height: 270px;
    position: relative;
}

/* Add specific styling for login state */
.signup-cta[key="login-cta"] {
    justify-content: center; /* Center the content vertically */
    padding-top: 50px; /* Add some top padding to push content down */
}

.signup-cta h4 {
    font-size: 29px;
    font-weight: 600;
    color: var(--primary-color-dark);
    margin-top: 0;
    white-space: nowrap;
}

.login-cta-text {
    font-size: 22px;
    font-weight: 400;
    color: var(--primary-color-dark);
    margin-top: 30px; /* Increase top margin */
    display: flex;
    align-items: center;
    margin-bottom: 100px; /* Reduce bottom margin if needed */
}

.login-arrow {
    margin-right: 8px;
}

.signup-cta-text {
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
}

.create-lab-button {
    background-color: var(--primary-color);
    color: white;
    font-family: var(--font-family);
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-start;
}

.create-lab-button:hover {
    background-color: var(--primary-color-dark);
}

@media (max-width: 986px) {
    .hero-image {
        height: 60vh;
    }

    .signup-section {
        margin-top: 6vh;
        padding: 20px 0 40px;
    }

    .signup-content {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .signup-hero-container {
        position: relative;
        top: -10px;
        width: 100%;
        max-width: 400px;
        margin: 0 auto 20px;
        padding: 20px;
        box-sizing: border-box;
    }

    .signup-cta {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        padding: 0;
        align-items: center;
    }

    .create-lab-button {
        align-self: center;
    }

    .howItWorksSection {
        width: 100%;
        padding: 0;
    }

    .howItWorksContent {
        width: 100%;
        padding: 40px 5% 0 !important; /* Increased top padding from 0 to 40px */
    }

    .landingPageSection {
        margin: 0;
        width: 100%;
    }
}

.findLabsSection {
    height: auto;
    min-height: 250px;
    padding-bottom: 40px;
}

.hero-content {
    position: relative;
    margin-top: 5vh;
}

.howItWorksSection {
    padding: 80px 0;
    background: linear-gradient(135deg, #f6f9ff 0%, #f0f4ff 100%);
    position: relative;
    overflow: hidden;
}

.howItWorksSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(
        90deg,
        var(--primary-color),
        #8c9eff,
        var(--primary-color)
    );
}

.howItWorksContent {
    max-width: var(--center-width);
    margin: 0 auto;
    padding: 0 5%;
}

.howItWorksContent .landingPageTitleText {
    text-align: center;
    margin-bottom: 40px;
}

.journey-subtitle {
    font-weight: 400;
    font-size: 18px;
    color: #666;
    margin: 10px 0 0;
    text-align: center;
}

.journey-container {
    position: relative;
    margin-top: 50px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.journey-background {
    position: absolute;
    top: 0;
    left: 50%;
    width: 4px;
    height: calc(100% - 100px);
    background: linear-gradient(
        to bottom,
        var(--primary-color),
        rgba(63, 81, 181, 0.1)
    );
    transform: translateX(-50%);
    z-index: 0;
    border-radius: 2px;
}

.journey-card {
    position: relative;
    width: 95%;
    max-width: 1000px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
    padding: 35px;
    z-index: 1;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.journey-card-left {
    width: 60%;
    position: relative;
}

.journey-card-number {
    position: absolute;
    top: 0px; /* Move up to overlap with the card's top edge */
    left: 0;
    width: 50px;
    height: 50px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0 4px 15px rgba(63, 81, 181, 0.3);
    z-index: 5; /* Ensure it appears above the card */
}

.journey-card h4 {
    font-size: 26px;
    font-weight: 600;
    color: #333;
    margin: 0 0 5px 0;
    padding-left: 70px; /* Increase to give space for the number */
}

.journey-card-subtitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-color);
    margin: 0 0 25px 0;
    padding-left: 70px; /* Match the padding of the h4 */
}

.journey-card-text p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin: 0;
}

.journey-illustration {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    overflow: hidden;
}

.svg-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.journey-svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: none;
}

/* Add glow effect to the lightbulb */
@keyframes glow {
    0% {
        filter: drop-shadow(0 0 5px rgba(63, 81, 181, 0.5));
    }
    50% {
        filter: drop-shadow(0 0 15px rgba(63, 81, 181, 0.8));
    }
    100% {
        filter: drop-shadow(0 0 5px rgba(63, 81, 181, 0.5));
    }
}

.journey-start-button {
    margin-top: 20px;
    z-index: 1;
}

.start-journey-button {
    background-color: var(--primary-color) !important;
    font-family: var(--font-family) !important;
    text-transform: none !important;
    font-size: 16px !important;
    padding: 12px 30px !important;
    box-shadow: 0 4px 20px rgba(63, 81, 181, 0.3) !important;
    font-weight: 500 !important;
}

/* Media queries for responsive design */
@media (max-width: 986px) {
    .journey-card {
        flex-direction: column;
        padding: 40px 25px 30px; /* Increase top padding to accommodate the number */
    }

    .journey-card-left {
        width: 100%;
    }

    .journey-card-number {
        width: 45px;
        height: 45px;
        font-size: 18px;
    }

    .journey-card h4 {
        padding-left: 60px;
        font-size: 22px;
    }

    .journey-card-subtitle {
        padding-left: 60px;
        font-size: 15px;
    }

    .journey-illustration {
        width: 100%;
        padding-left: 0;
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .journey-card-number {
        width: 45px;
        height: 45px;
        font-size: 18px;
    }

    .journey-card h4 {
        padding-left: 55px;
        font-size: 20px;
    }

    .journey-card-subtitle {
        padding-left: 55px;
        font-size: 14px;
    }
}

.signup-cta-steps {
    margin-bottom: -15x;
}

.signup-cta-steps h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

.signup-cta-steps ol {
    list-style-type: none;
    padding-left: 0;
}

.signup-cta-steps li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    color: #4a4a4a;
}

.step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #2c3e50;
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
}

.step-number {
    color: white;
    font-weight: bold;
}

.step-arrow {
    position: absolute;
    left: -25px;
    color: #2c3e50;
    font-size: 30px !important;
    font-weight: bold !important;
}

@media (max-width: 986px) {
    .signup-cta-steps {
        text-align: left;
        width: 100%;
    }

    .signup-cta-steps h4 {
        text-align: left;
    }

    .signup-cta-steps ol {
        display: inline-block;
    }
}

.create-lab-link {
    display: inline-flex;
    align-items: center;
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    transition: color 0.3s ease;
    font-size: 18px;
    justify-content: flex-start;
    gap: 5px;
    z-index: 10;
    pointer-events: auto;
}

.create-lab-link:hover {
    color: var(--primary-color-dark);
    cursor: pointer;
}

.create-lab-link .MuiSvgIcon-root {
    margin-left: 5px;
    font-size: 18px;
}

.signup-cta > div {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* Update these media queries */
@media (max-width: 986px) {
    .signup-section {
        width: 100vw; /* Changed from 100% to 100vw */
        margin-top: 6vh;
        padding: 20px 0 40px;
        overflow-x: hidden; /* Prevent horizontal scroll */
    }

    .howItWorksSection {
        width: 100vw; /* Changed from 100% to 100vw */
        padding: 0;
        overflow-x: hidden; /* Prevent horizontal scroll */
    }

    .landingPageSection {
        width: 100vw; /* Changed from 100% to 100vw */
        margin: 0;
        overflow-x: hidden; /* Prevent horizontal scroll */
    }
}

/* Add or update these rules */
:root {
    --center-width: 1200px;
    --window-threshold: 987px;
    --font-family: "poppins", Helvetica, Arial, sans-serif;
    --primary-color-dark: #333;
}

/* Update media queries */
@media (max-width: 986px) {
    .signup-section,
    .howItWorksSection,
    .landingPageSection {
        width: 100%; /* Change back to 100% */
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;
        box-sizing: border-box; /* Add this */
    }

    /* Add this to ensure content padding doesn't cause overflow */
    .signup-content,
    .howItWorksContent,
    .landingPageSection > * {
        box-sizing: border-box;
        width: 100%;
    }
}

/* Animation and new UI component styles */

/* Scroll indicator */
.scroll-indicator {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.scroll-indicator span {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.scroll-indicator:hover {
    opacity: 0.8;
}

/* Research Assistant Section */
.researchAssistantSection {
    padding: 60px 0;
    background: linear-gradient(to bottom, #f9f9f9, white);
}

.researchAssistantContent {
    max-width: var(--center-width);
    margin: 0 auto;
    padding: 0 5%;
}

.researchAssistantContent .landingPageTitleText {
    text-align: center;
    margin-bottom: 50px;
}

.researchAssistantContent .landingPageTitleText h4 {
    font-weight: 400;
    font-size: 22px;
    margin-top: 10px;
    color: #666;
}

.researchAssistantFeaturesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.researchAssistantVisual {
    flex: 1;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(
        circle,
        rgba(63, 81, 181, 0.1) 0%,
        rgba(255, 255, 255, 0) 70%
    );
    border-radius: 50%;
    aspect-ratio: 1/1;
    padding: 20px;
}

.researchAssistantFeatures {
    flex: 1.5;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.featureItem {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featureItem:hover {
    transform: translateX(5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.featureItem h5 {
    font-size: 18px;
    margin: 0 0 10px 0;
    color: var(--primary-color);
}

.featureItem p {
    margin: 0;
    font-size: 15px;
    line-height: 1.5;
    color: #555;
}

.try-assistant-button {
    margin-top: 15px !important;
    align-self: flex-start;
    padding: 10px 25px !important;
    font-family: var(--font-family) !important;
    text-transform: none !important;
    font-size: 16px !important;
}

/* FAQ Section */
.faqSection {
    padding: 60px 0 80px;
    background-color: white;
}

.faqContent {
    max-width: var(--center-width);
    margin: 0 auto;
    padding: 0 5%;
}

.faqContent .landingPageTitleText {
    text-align: center;
    margin-bottom: 40px;
}

.faqContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 800px;
    margin: 0 auto;
}

.faqItem {
    background-color: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.faqItem.expanded {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.faqQuestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
}

.faqQuestion h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.faqAnswer {
    padding: 0 20px 20px;
    overflow: hidden;
}

.faqAnswer p {
    margin: 0;
    line-height: 1.6;
    color: #555;
}

.faqCta {
    text-align: center;
    margin-top: 40px;
}

.faqCta p {
    margin-bottom: 15px;
    font-size: 18px;
    color: #666;
}

/* Media queries for responsive design */
@media (max-width: 986px) {
    .researchAssistantFeaturesContainer {
        flex-direction: column;
    }

    .researchAssistantVisual {
        width: 200px;
        height: 200px;
        margin: 0 auto 30px;
    }

    .researchAssistantIcon svg {
        font-size: 100px !important;
    }

    .featureItem {
        width: 100%;
    }

    .try-assistant-button {
        align-self: center;
        width: 100%;
        max-width: 300px;
    }

    .faqQuestion h5 {
        font-size: 16px;
        padding-right: 10px;
    }

    .faqCta p {
        font-size: 16px;
    }

    .landingPageSection {
        padding: 40px 0;
    }

    .researchAssistantContent .landingPageTitleText,
    .faqContent .landingPageTitleText {
        text-align: left;
    }

    .researchAssistantContent .landingPageTitleText h4 {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .scroll-indicator {
        bottom: 20px;
    }

    .researchAssistantVisual {
        width: 150px;
        height: 150px;
    }

    .researchAssistantIcon svg {
        font-size: 80px !important;
    }

    .featureItem h5 {
        font-size: 16px;
    }

    .featureItem p {
        font-size: 14px;
    }

    .faqQuestion {
        padding: 15px;
    }

    .faqAnswer {
        padding: 0 15px 15px;
    }
}

/* Fix for journey-card-content that needs to be removed */
.journey-card-content {
    display: none; /* Hide the old structure */
}

/* Additional fix for cards 2 and 3 */
.journey-card h4,
.journey-card .journey-card-subtitle,
.journey-card .journey-card-text {
    width: 100%; /* Ensure these take full width of their container */
}

/* Fix for cards that don't have journey-card-left properly set */
.journey-card {
    position: relative;
    width: 95%;
    max-width: 1000px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
    padding: 35px;
    z-index: 1;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

/* Fix for cards 2 and 3 that don't have the proper structure */
.journey-card > .journey-card-number:first-child {
    position: absolute;
    top: -20px;
    left: 30px;
    width: 60px;
    height: 60px;
    z-index: 10;
}

.journey-card > h4:nth-child(2) {
    width: 60%;
    margin-top: 15px;
    padding-right: 20px;
}

.journey-card > .journey-card-subtitle:nth-child(3) {
    width: 60%;
    padding-right: 20px;
}

.journey-card > .journey-card-content:nth-child(4) {
    display: flex;
    width: 60%;
}

.journey-card > .journey-card-content > .journey-card-text {
    width: 100%;
}

/* Fix for the illustration in cards 2 and 3 */
.journey-card > .journey-card-content:nth-child(4) > .journey-illustration {
    position: absolute;
    top: 35px;
    right: 35px;
    width: 40%;
    bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Simple color animation for the lightbulb */
.animated-lightbulb {
    filter: hue-rotate(0deg);
    animation: colorShift 8s infinite ease-in-out;
}

@keyframes colorShift {
    0% {
        filter: hue-rotate(0deg);
    } /* Original color */
    25% {
        filter: hue-rotate(90deg);
    } /* Shift to greenish */
    50% {
        filter: hue-rotate(180deg);
    } /* Shift to cyan/blue */
    75% {
        filter: hue-rotate(270deg);
    } /* Shift to purple/pink */
    100% {
        filter: hue-rotate(360deg);
    } /* Back to original */
}

/* Add these styles to animate just the lightbulb */
@keyframes bulbColorChange {
    0% {
        fill: #000000;
    }
    25% {
        fill: #3f51b5;
    } /* Primary blue */
    50% {
        fill: #f44336;
    } /* Red */
    75% {
        fill: #4caf50;
    } /* Green */
    100% {
        fill: #000000;
    }
}

/* This style will be applied by the JavaScript to the lightbulb path */
.animated-bulb-path {
    animation: bulbColorChange 8s infinite ease-in-out;
}

/* Make sure the SVG container is properly sized and positioned */
.svg-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/* Add these styles for the inline SVG approach */
@keyframes bulbColorChange {
    0% {
        fill: #000000;
    }
    25% {
        fill: #3f51b5;
    } /* Primary blue */
    50% {
        fill: #f44336;
    } /* Red */
    75% {
        fill: #4caf50;
    } /* Green */
    100% {
        fill: #000000;
    }
}

.lightbulb-path {
    animation: bulbColorChange 8s infinite ease-in-out;
}

/* Update the bulb color change animation to make it more visible and prevent conflicts */
@keyframes lightbulbColorChange {
    0% {
        fill: #000000;
    }
    20% {
        fill: #3f51b5;
    } /* Blue */
    40% {
        fill: #f44336;
    } /* Red */
    60% {
        fill: #4caf50;
    } /* Green */
    80% {
        fill: #ff9800;
    } /* Amber */
    100% {
        fill: #000000;
    }
}

/* Make sure this class has higher specificity to override any other styles */
svg .lightbulb-path {
    animation: lightbulbColorChange 8s infinite ease-in-out !important;
}

/* Add a slight glow effect for the lightbulb to enhance visibility */
.journey-svg g path.lightbulb-path {
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.3));
}

/* Fix any potential animation issues in different browsers */
@supports (-moz-appearance: none) {
    .lightbulb-path {
        transform-origin: center;
        will-change: fill;
    }
}

/* Add these styles to your Home.css file */

.journey-card-button-container {
    margin-top: 20px;
}

.journey-step-button {
    background-color: transparent !important;
    border: 2px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
    padding: 10px 20px !important;
    font-weight: 600 !important;
    transition: all 0.3s ease !important;
}

.journey-step-button:hover {
    background-color: var(--primary-color) !important;
    color: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

/* Ensure the journey cards have enough space for the buttons */

.journey-card-left {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.journey-card-text {
    flex-grow: 1;
}

/* Responsive adjustments for journey card buttons */
@media (max-width: 986px) {
    .journey-step-button {
        width: 100%;
        margin-top: 10px;
        padding: 10px 16px !important;
    }

    .journey-card-subtitle {
        margin: 0 0 15px 0;
    }

    .journey-card-text > p {
        font-size: 16px;
    }

    .journey-card-button-container {
        width: 100%;
    }
}

/* Responsive animation improvements */
@media (max-width: 768px) {
    .journey-card {
        flex-direction: column;
        padding: 40px 20px 30px;
    }

    .journey-card-left {
        width: 100%;
    }

    .journey-card-text > p {
        font-size: 14px;
    }

    .journey-illustration {
        width: 100%;
        margin-top: 20px;
        padding-left: 0;
    }

    .journey-svg {
        width: 70%;
        margin: 0 auto;
    }

    .researchAssistantFeaturesContainer {
        flex-direction: column;
    }

    .researchAssistantVisual {
        width: 120px;
        height: 120px;
        margin: 0 auto 30px;
    }

    .researchAssistantIcon svg {
        font-size: 80px !important;
    }
}

/* Optimize animations for mobile */
@media (max-width: 480px) {
    .hero-text h1 {
        font-size: 32px !important;
    }

    /* Reduce animation distances for mobile */
    .journey-card,
    .featureItem,
    .faqItem,
    .researchAssistantContent,
    .faqContent {
        transform: none !important; /* Reset any transform animations */
    }

    /* Optimize hero animations */
    .hero-text,
    .signup-section {
        transform: none !important;
    }
}

/* Improve scrolling experience */
.scrollable-content {
    overflow-x: hidden;
    position: relative;
    width: 100%;
}

/* Optimize journey section layout */
.journey-container {
    position: relative;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

/* Improve animation performance */
.journey-card,
.featureItem,
.faqItem {
    will-change: transform, opacity;
    backface-visibility: hidden;
}

/* Fix for journey cards on small screens */
@media (max-width: 1024px) {
    /* Override the existing problematic structure */
    .journey-card {
        flex-direction: column;
        padding: 30px 20px 30px;
        width: 90%;
    }

    /* Force proper display of content in journey-card-left */
    .journey-card-left {
        width: 100%;
        padding-right: 0;
        display: flex;
        flex-direction: column;
    }

    /* Explicitly order elements */
    .journey-card-left
        > *:not(.journey-card-button-container):not(.journey-illustration) {
        order: 1;
    }

    .journey-card-left > .journey-illustration,
    .journey-illustration {
        order: 3 !important; /* Move image below the button */
    }

    .journey-card-left > .journey-card-button-container,
    .journey-card-button-container {
        order: 2 !important; /* Keep button above the image */
        margin-top: 20px !important;
    }

    /* Ensure illustrations always show up correctly */
    .journey-illustration {
        width: 100%;
        padding-left: 0;
        position: static !important;
        display: flex;
        justify-content: center;
    }

    /* Override any fixed positioning in card structure variations */
    .journey-card > .journey-card-content:nth-child(4) > .journey-illustration,
    .journey-card .journey-illustration {
        position: static !important;
        width: 100% !important;
    }

    /* Set specific values for image dimensions */
    .journey-svg {
        width: 250px;
        max-width: 60%;
        height: auto;
    }

    /* Style for buttons after adjusting order */
    .journey-step-button {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }

    /* For cards with content directly inside journey-card */
    .journey-card > h4:nth-child(2),
    .journey-card > .journey-card-subtitle:nth-child(3),
    .journey-card > .journey-card-text {
        order: 1 !important;
    }

    .journey-card > .journey-card-button-container {
        order: 2 !important;
    }

    .journey-card > .journey-illustration,
    .journey-card > .journey-card-content > .journey-illustration {
        order: 3 !important;
    }

    /* Force flex layout and clear floating behaviors */
    .journey-card {
        display: flex !important;
        flex-direction: column !important;
    }

    /* Handle nested journeys with journey-card-content wrapper */
    .journey-card > .journey-card-content {
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
    }

    .journey-card > .journey-card-content > .journey-card-text {
        order: 1 !important;
    }

    .journey-card > .journey-card-content > .journey-card-button-container {
        order: 2 !important;
    }

    .journey-card > .journey-card-content > .journey-illustration {
        order: 3 !important;
    }

    /* Ensure ALL journey cards show content in this order, regardless of structure */
    .journey-card {
        display: flex !important;
        flex-direction: column !important;
    }

    .journey-card .journey-illustration {
        order: 2 !important;
    }

    .journey-card .journey-card-button-container {
        order: 3 !important;
    }
}

/* Update the desktop/mobile breakpoint for button positioning */
@media (min-width: 1025px) {
    .journey-card {
        height: 400px;
    }
    .journey-card-button-container {
        margin-top: 20px;
        width: auto;
    }

    .journey-card > .journey-card-button-container {
        position: absolute;
        bottom: 35px;
        width: calc(60% - 70px);
    }
}

@media (max-width: 1024px) {
    .journey-card-button-container {
        width: 100%;
        margin-top: 20px;
        order: 3;
    }

    .journey-illustration {
        order: 2;
        margin-top: 20px;
    }

    .journey-card-subtitle {
        margin-bottom: 12px;
    }

    .journey-card-text {
        margin-bottom: 10px;
    }
}

/* Contact Footer Styles */
.home-contact-footer {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-top: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}

.contact-email {
    font-weight: 500;
    color: var(--primary-color);
    margin: 0;
}

.social-links a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: var(--primary-color-light);
    text-decoration: underline;
}

.main-links {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.main-links a {
    color: #555;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s ease;
}

.main-links a:hover {
    color: var(--primary-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contact-info {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .main-links {
        gap: 15px;
    }
}

/* Home Footer Styles */
.home-footer {
    background: linear-gradient(to right, #f5f7ff, #e8ecfd);
    padding: 60px 0 0;
    position: relative;
    z-index: 10;
    border-top: 1px solid rgba(63, 81, 181, 0.15);
}

.home-footer-content {
    max-width: var(--center-width);
    margin: 0 auto;
    padding: 0 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
}

.home-footer-logo {
    flex: 1;
    min-width: 220px;
}

.home-footer-logo h2 {
    font-size: 32px;
    font-weight: 700;
    color: var(--primary-color);
    margin: 0 0 10px;
    letter-spacing: -1px;
}

.home-footer-logo p {
    font-size: 16px;
    color: #555;
    margin: 0;
}

.home-footer-links {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.home-footer-nav {
    flex: 1;
    min-width: 220px;
}

.home-footer-nav h3,
.home-footer-contact h3 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0 0 20px;
}

.home-footer-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.home-footer-nav li {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 16px;
    color: #555;
    cursor: pointer;
    transition: color 0.2s ease, transform 0.2s ease;
}

.home-footer-nav li:hover {
    color: var(--primary-color);
    transform: translateX(5px);
}

.home-footer-nav li svg {
    font-size: 20px;
    margin-right: 10px;
    color: var(--primary-color);
}

.home-footer-contact {
    flex: 1;
    min-width: 220px;
}

.home-footer-contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 16px;
    color: #555;
}

.home-footer-contact-item svg {
    font-size: 20px;
    margin-right: 10px;
    color: var(--primary-color);
}

.home-footer-contact-item.linkedin {
    cursor: pointer;
    transition: color 0.2s ease;
}

.home-footer-contact-item.linkedin:hover {
    color: var(--primary-color);
}

.home-footer-join {
    margin-top: 25px;
}

.home-footer-join-button {
    background-color: var(--primary-color) !important;
    font-family: var(--font-family) !important;
    text-transform: none !important;
    font-size: 16px !important;
    padding: 8px 20px !important;
    border-radius: 4px !important;
    box-shadow: 0 4px 10px rgba(63, 81, 181, 0.2) !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.home-footer-join-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(63, 81, 181, 0.3) !important;
}

.home-footer-bottom {
    margin-top: 50px;
    padding: 20px 0;
    border-top: 1px solid rgba(63, 81, 181, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    max-width: var(--center-width);
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
}

.home-footer-bottom p {
    margin: 0;
    font-size: 14px;
    color: #666;
}

.home-footer-bottom-links {
    display: flex;
    gap: 20px;
}

.home-footer-bottom-links span {
    font-size: 14px;
    color: #666;
    cursor: pointer;
    transition: color 0.2s ease;
}

.home-footer-bottom-links span:hover {
    color: var(--primary-color);
}

/* Responsive styles for the footer */
@media (max-width: 986px) {
    .home-footer {
        padding: 40px 0 0;
    }

    .home-footer-content {
        flex-direction: column;
        gap: 30px;
    }

    .home-footer-logo {
        text-align: center;
    }

    .home-footer-links {
        flex-direction: column;
        gap: 30px;
    }

    .home-footer-join {
        display: flex;
        justify-content: center;
    }

    .home-footer-bottom {
        flex-direction: column;
        text-align: center;
        gap: 15px;
    }

    .home-footer-bottom-links {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .home-footer {
        padding: 30px 0 0;
    }

    .home-footer-logo h2 {
        font-size: 28px;
    }

    .home-footer-nav h3,
    .home-footer-contact h3 {
        font-size: 16px;
    }

    .home-footer-nav li,
    .home-footer-contact-item {
        font-size: 14px;
    }

    .home-footer-join-button {
        font-size: 14px !important;
        padding: 6px 16px !important;
    }
}
