/* add important tag to all styling */
.lab-profile-content-members-tab-members {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px)) !important;
    gap: 20px !important;
    justify-content: start !important;
    max-width: 100% !important;
    margin-top: 20px !important;
}

.lab-profile-content-members-tab-member {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    text-align: left !important;
    transition: all 0.3s ease !important;
    width: 250px !important;
    box-shadow: none !important;
}

.lab-profile-content-members-tab-member h4 {
    font-size: 22px !important;
    font-weight: 500 !important;
    margin: 15px 0 10px !important;
}

.lab-profile-content-members-tab-member h4:hover {
    cursor: pointer;
}

.lab-profile-content-members-tab-member h5 {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #621f1f !important;
    margin: 0 0 8px 0 !important;
}

.lab-profile-content-members-tab-member p {
    font-size: 13px !important;
    line-height: 1.4 !important;
    color: #666 !important;
    margin: 0 !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
}

.lab-profile-content-members-tab-member-image {
    width: 250px !important;
    height: 250px !important;
    overflow: hidden !important;
    position: relative !important;
    background-color: #d0d0d0 !important;
    cursor: pointer !important;
}

.lab-profile-content-members-tab-member-image::before {
    content: "" !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 85% !important;
    height: 85% !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M12 11.5c2.07 0 3.75-1.68 3.75-3.75S14.07 4 12 4 8.25 5.68 8.25 7.75 9.93 11.5 12 11.5zm0 2c-3.17 0-6.75 1.53-6.75 4.5v2h13.5v-2c0-2.97-3.58-4.5-6.75-4.5z'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    opacity: 0.6 !important;
    pointer-events: none !important;
    z-index: 1 !important;
}

.lab-profile-content-members-tab-member-image img:hover {
    /* Remove this rule or remove cursor: pointer */
}

.lab-profile-content-members-tab-member-image img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    position: relative !important;
    z-index: 2 !important;
}

.lab-profile-content-members-tab-member-image img[src=""],
.lab-profile-content-members-tab-member-image img[src="undefined"],
.lab-profile-content-members-tab-member-image img:not([src]) {
    display: none !important;
}

/* New and modified styles */
.lab-top-section {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    margin-bottom: 20px !important;
}

.group-description {
    flex: 0 1 auto !important;
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    margin-right: 20px !important;
    min-width: 200px !important;
    max-width: 40% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
}

.group-description p {
    font-size: 14px !important;
    line-height: 1.6 !important;
    color: #333 !important;
    margin: 0 !important;
    max-height: 100% !important;
    overflow: auto !important;
    white-space: pre-line !important;
}

.lab-group-picture-container {
    flex: 1 0 auto !important;
    max-width: 60% !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-end !important;
}

.lab-group-picture {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: contain !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

/* Adjust the media query for smaller screens */
@media (max-width: 1200px) {
    .group-description {
        max-width: 45% !important;
    }

    .lab-group-picture-container {
        max-width: 55% !important;
    }
}

@media (max-width: 1024px) {
    .lab-top-section {
        flex-direction: column !important;
        min-height: auto !important;
    }

    .lab-members-subheader {
        margin-top: 40px !important;
    }

    .group-description,
    .lab-group-picture-container {
        width: 100% !important;
        max-width: 100% !important;
    }

    .group-description {
        margin-right: 0 !important;
        margin-bottom: 20px !important;
    }

    .lab-group-picture-container {
        justify-content: center !important;
    }

    .lab-group-picture {
        max-height: 400px !important;
    }

    .lab-profile-content-members-tab-members {
        padding-left: 25px !important;
    }

    .lab-profile-content-members-tab-member {
        padding: 20px !important;
    }
}
