.unsplash-modal-content {
    padding: 1rem;
    max-height: 70vh; /* Limit height */
    overflow-y: auto; /* Allow scrolling */
    display: flex;
    flex-direction: column;
}

.unsplash-search-form {
    display: flex;
    gap: 10px;
    margin-bottom: 1rem;
}

.unsplash-search-input {
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.unsplash-search-button {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.unsplash-search-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.unsplash-search-button:hover:not(:disabled) {
    background-color: var(
        --primary-dark-color
    ); /* Define this color if needed */
}

.unsplash-error {
    color: red;
    text-align: center;
    margin-bottom: 1rem;
}

.unsplash-image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin-bottom: 1rem;
}

.unsplash-image-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;
    aspect-ratio: 1 / 1; /* Make items square */
}

.unsplash-image-item img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.unsplash-image-item:hover img {
    transform: scale(1.05);
}

.unsplash-image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    color: white;
    padding: 0.5rem;
    font-size: 0.8rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
}

.unsplash-image-item:hover .unsplash-image-overlay {
    opacity: 1;
}

.unsplash-image-overlay p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.unsplash-load-more {
    display: block;
    margin: 1rem auto;
    padding: 0.6rem 1.5rem;
    background-color: #eee;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.unsplash-load-more:hover:not(:disabled) {
    background-color: #ddd;
}

.unsplash-load-more:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
