:root {
    --listing-height: 75vh;
    --listing-width: 150vh;
}

.listings-manager-container {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

@media (max-width: 768px) {
    .listings-manager-container {
        padding: 10px;
    }
}

.listings-manager-filter {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    min-height: 40px;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .listings-manager-filter {
        flex-direction: column;
        align-items: stretch;
    }

    .listings-manager-title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .listings-manager-create-listing {
        width: 100%;
    }
}

.listings-manager-filter-time,
.listings-manager-filter-facets {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
    padding: 10px !important;
    border-radius: 5px !important;
    background-color: white !important;
    color: black !important;
    margin-left: 0 !important;
    margin-right: 10px !important;
    display: inline-flex !important;
    align-items: center !important;
    gap: 5px;
    height: 40px; /* Set a fixed height for the buttons */
}

.listings-manager-create-listing {
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-flex !important;
    color: var(--primary-color);
    border: none;
    cursor: pointer;
    font-size: 16px !important;
    border-radius: 5px !important;
    outline: none !important;
    height: 40px !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 5px;
}

.listings-manager-content {
    display: flex;
    justify-content: center;
}

.listings-manager-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px; /* Adjust gap as desired */
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 20px 0 40px;
}

@media (max-width: 1200px) {
    .listings-manager-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (max-width: 768px) {
    .listings-manager-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 15px;
    }
}

.listings-manager-title {
    font-size: 24px;
    font-weight: 300 !important;
    letter-spacing: 0.5px;
    color: #454545;
}

.listings-manager-listing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 375px;
    max-height: 375px;
    max-width: 100%;
    background-color: white;
    height: auto; /* Height adjusts based on content */
    letter-spacing: 0.5px;
}

.listings-manager-listing:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: transform 0.3s;
}

.listings-manager-listing:not(:hover) {
    transform: scale(1);
    transition: transform 0.3s;
}

.listings-manager-listing-img {
    width: 100%; /* Full width of the container */
    height: auto; /* Maintain aspect ratio */
    max-height: 200px; /* Limit height to keep uniformity */
    min-height: 200px; /* Ensure minimum height */
    object-fit: cover; /* Ensure the image covers the area well */
}

.listings-manager-listing-info {
    position: relative;
    text-align: center;
    flex: 1;
    margin-top: 15px;
    width: 100%;
}

.listings-manager-listing-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 100%;
    padding-top: 5px;
    font-size: 12px;
    display: inline-flex;
    flex: 1;
    align-items: center;
    color: #454545;
}

.listings-manager-listing-name {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    max-width: 275px; /* Adjust this value based on your specific layout needs */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.listings-manager-listing-price {
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;
}

.listings-manager-listing-description {
    text-align: left;
    font-size: 12px;
    color: #787878;
    display: -webkit-box; /* Use flexbox for truncation */
    -webkit-box-orient: vertical; /* Set the box orientation to vertical */
    -webkit-line-clamp: 5; /* Limit to 6 lines */
    overflow: hidden; /* Hide overflow text */
}

.listings-manager-listing-badges {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
}

.listings-manager-listing-lister {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #ffd700;
    border-radius: 5px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
}

.listings-manager-listing-type {
    background-color: #60d0fe;
    border-radius: 5px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    gap: 3px;
    font-size: 12px;
}

.listings-manager-listing-lister span {
    margin-left: 3px;
    font-size: 12px;
}

.listings-manager-listing-lister:hover {
    background-color: #e6c300;
    color: black;
    transition: 0.7s;
    cursor: pointer;
}

.listings-manager-listing-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.listings-manager-listing-buttons > button {
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 5px;
    align-items: center;
    color: white;
}

.listings-manager-listing-buttons > button:first-child {
    background-color: var(--primary-color);
}

.listings-manager-listing-buttons > button:nth-child(2) {
    background-color: var(--primary-color);
}

.filter-container {
    position: relative;
}

.filter-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.filter-dropdown {
    z-index: 100;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 300px; /* Adjust width as needed */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin-right: 15px;
    left: -100%; /* Initially position the component outside the viewport */
    animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
    from {
        transform: translateX(-25%); /* Start position outside the viewport */
        opacity: 0; /* Start with opacity 0 */
    }
    to {
        transform: translateX(0); /* End position at left: 0 */
        opacity: 1; /* Fully visible */
    }
}

.facet {
    margin-bottom: 10px;
}

.facet-title {
    width: 300px;
    font-weight: bold;
    text-align: left;
    font-size: 20px;
    padding: 20px 20px 0 20px;
    display: flex;
}

.facet-title:hover {
    cursor: pointer;
}

.facet-title-arrow {
    margin-left: auto;
    transition: transform 0.3s ease-in-out;
}

.facet-title-arrow.up {
    transform: rotate(180deg);
}

.facet-options {
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    overflow: hidden;
    max-height: 500;
    opacity: 1;
}

.facet-options {
    width: 100%;
    padding: 0 20px;
    text-align: left;
    border-radius: 3px;
    outline: none;
}

.facet-option {
    display: flex;
    align-items: center;
    gap: 10px;
}

.facet-option-checkbox {
    width: 20px;
    height: 20px;
}

.time-period-button {
    background-color: #fff !important; /* Match the color of listings-manager-filter-facets */
    color: black !important;
    border: none !important;
    margin-left: 10px !important; /* Space between the filter and time period buttons */
    cursor: pointer !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    outline: none !important;
    width: 100% !important;
    height: 40px !important; /* Set a fixed height for the buttons */
    display: flex !important; /* Use flexbox for alignment */
    align-items: center !important; /* Vertically center the content */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
    justify-content: center !important;
    gap: 5px;
}

.time-period-dropdown {
    position: relative !important;
    text-align: center !important;
}

.time-period-list {
    position: absolute !important;
    top: 100% !important; /* Position directly below the button */
    left: 0 !important;
    margin-left: 10px !important; /* Ensure no margin on the left */
    margin-top: 0 !important; /* Ensure no margin on the top */
    background-color: #fff !important; /* Match this with your filter-dropdown background */
    border: 1px solid #ddd !important; /* Example border, match with your actual dropdown */
    border-radius: 5px !important;
    width: 100% !important; /* Adjust width to match the button */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
    z-index: 1000 !important;
}

.time-period-item {
    padding: 8px 12px !important;
    cursor: pointer !important;
    text-align: center !important;
    list-style-type: none !important;
}

.time-period-item:hover {
    background-color: #f8f8f8 !important;
}
.listings-manager-listing-badges svg {
    cursor: pointer;
}

.facet-options ul {
    list-style-type: none;
    padding: 0;
}

.facet-option {
    padding: 5px 0;
}

.listings-manager-tabs {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    border-bottom: 1px solid #d0d0d0;
}

.listings-manager-tabs::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.listings-manager-tab-button {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #454545;
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.listings-manager-tab-button.active {
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.listings-manager-tab-button:hover {
    background-color: #f0f0f0;
}

@media (max-width: 768px) {
    .listings-manager-tab-button {
        padding: 10px 15px;
        font-size: 14px;
        white-space: nowrap;
    }
}

@media (max-width: 768px) {
    .listings-manager-listing {
        min-height: 325px;
        max-height: 325px;
    }

    .listings-manager-listing-img {
        max-height: 150px;
        min-height: 150px;
    }

    .listings-manager-listing-name {
        font-size: 16px;
        max-width: 200px;
    }

    .listings-manager-listing-description {
        -webkit-line-clamp: 4;
        font-size: 11px;
    }

    .listings-manager-listing-buttons > button {
        padding: 3px;
    }

    .listings-manager-listing-type {
        padding: 3px;
        font-size: 11px;
    }
}

@media (max-width: 768px) {
    .time-period-button {
        font-size: 14px !important;
    }

    .time-period-list {
        width: calc(100% - 20px) !important;
    }

    .time-period-item {
        padding: 10px !important;
        font-size: 14px !important;
    }
}

.listings-manager-actions {
    display: flex;
    align-items: center;
    gap: 15px;
}

.listings-manager-view-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: var(--primary-color);
    border: none;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.listings-manager-view-toggle:hover {
    background-color: #f0f0f0;
}

/* List view styles */
.listings-manager-list-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0 40px;
}

.listings-manager-list-item {
    display: flex;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s;
    padding: 15px;
    width: 100%;
}

.listings-manager-list-item:hover {
    transform: translateY(-3px);
    transition: transform 0.3s;
}

.listings-manager-list-content {
    width: 100%;
    position: relative;
}

.listings-manager-list-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
}

.listings-manager-list-description {
    font-size: 14px;
    color: #787878;
    margin-bottom: 40px;
    text-align: left;
}

.listings-manager-list-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .listings-manager-actions {
        width: 100%;
        justify-content: space-between;
    }

    .listings-manager-list-description {
        font-size: 12px;
        margin-bottom: 35px;
    }

    .listings-manager-list-title {
        font-size: 16px;
    }
}

/* Table view styles */
.listings-manager-table-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    margin: 20px 0 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.listings-manager-table-header {
    display: grid;
    grid-template-columns: 20% 15% 1fr auto;
    background-color: var(--primary-color);
    color: white;
    font-weight: 600;
    padding: 12px 15px;
    text-align: left;
    letter-spacing: 0.5px;
}

.listings-manager-table-row {
    display: grid;
    grid-template-columns: 20% 15% 1fr auto;
    padding: 12px 15px;
    background-color: white;
    border-bottom: 1px solid #eee;
    align-items: center;
    transition: background-color 0.2s ease;
    cursor: pointer;
}

.listings-manager-table-row:hover {
    background-color: #f9f9f9;
}

.listings-manager-table-row:last-child {
    border-bottom: none;
}

.listings-manager-table-title {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.listings-manager-table-type {
    padding-right: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.listings-manager-table-description {
    color: #666;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding-right: 15px;
}

/* Ensure header text has consistent color */
.listings-manager-table-header .listings-manager-table-description,
.listings-manager-table-header .listings-manager-table-title,
.listings-manager-table-header .listings-manager-table-type,
.listings-manager-table-header .listings-manager-table-actions {
    color: white;
    font-size: inherit;
}

/* Add relative positioning for resizer */
.header-cell {
    position: relative;
    overflow: visible; /* Ensure resizer isn't clipped */
}

/* Style for the draggable resizer handle */
.resizer {
    position: absolute;
    top: 0;
    right: -5px; /* Position slightly outside the cell boundary */
    width: 10px; /* Make it easier to grab */
    height: 100%;
    cursor: col-resize;
    z-index: 10; /* Ensure it's clickable */
    /* Optional: Add a visual indicator */
    /* border-right: 2px solid rgba(0, 0, 0, 0.1); */
}

.resizer:hover {
    /* Optional: visual feedback on hover */
    /* background-color: rgba(0, 0, 0, 0.1); */
}

/* Prevent text selection during resize */
.resizing {
    user-select: none;
}

.listings-manager-table-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.listings-manager-table-actions button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.listings-manager-table-actions button:hover {
    background-color: var(--primary-color-dark, #0056b3);
}

@media (max-width: 1024px) {
    .listings-manager-table-header,
    .listings-manager-table-row {
        grid-template-columns: 25% 20% 1fr auto;
    }
}

@media (max-width: 768px) {
    .listings-manager-table-header,
    .listings-manager-table-row {
        grid-template-columns: 30% 25% 1fr auto;
        padding: 10px;
    }

    .listings-manager-table-description {
        font-size: 12px;
    }

    .listings-manager-table-actions button {
        padding: 4px;
    }
}

@media (max-width: 576px) {
    .listings-manager-table-header,
    .listings-manager-table-row {
        grid-template-columns: 40% 30% 1fr auto;
        gap: 5px;
    }

    .listings-manager-table-header {
        font-size: 14px;
    }
}
