:root {
    --font-family: "poppins", Helvetica, Arial, sans-serif;
    --primary-color: rgb(63, 05, 05);
    --primary-color-light: rgb(83, 25, 25);
    --primary-color-dark: rgb(43, 0, 0);
    --complementary-color: #053f3f;
    --primary-gray: #f0f0f0;
}

/* Global rule to apply primary font to all Material UI components in pricing section */
.pricing-page .MuiTypography-root,
.pricing-page .MuiButton-root,
.pricing-page .MuiChip-root,
.pricing-page .MuiTooltip-root,
.pricing-page .MuiAlert-root {
    font-family: var(--font-family) !important;
}

.pricing-page {
    padding: 80px 0;
    background-color: #fff;
    min-height: 100vh;
    font-family: var(--font-family);
    background-image: linear-gradient(to bottom, #fff, #f9f9f9);
    position: relative;
    overflow: hidden;
}

.pricing-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
            circle at 10% 10%,
            rgba(63, 5, 5, 0.03) 0%,
            transparent 20%
        ),
        radial-gradient(
            circle at 90% 90%,
            rgba(63, 5, 5, 0.03) 0%,
            transparent 20%
        );
    z-index: 0;
    pointer-events: none;
}

.pricing-header {
    text-align: center;
    margin-bottom: 70px;
    position: relative;
    z-index: 1;
}

.pricing-header::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background-color: var(--primary-color);
    border-radius: 3px;
}

.pricing-main-title {
    margin-bottom: 16px;
    font-weight: 700;
    color: #333;
    font-family: var(--font-family);
    letter-spacing: -0.5px;
}

.pricing-subtitle {
    margin-bottom: 12px;
    color: #666;
    font-family: var(--font-family);
}

.pricing-grid {
    margin-bottom: 80px;
    position: relative;
    z-index: 1;
}

.pricing-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
    border: 1px solid #e0e0e0;
    position: relative;
    background-color: #fff;
    font-family: var(--font-family);
}

/* Apply primary font to all text elements in pricing cards */
.pricing-card * {
    font-family: var(--font-family);
}

/* Target any Material UI typography components */
.pricing-card .MuiTypography-root {
    font-family: var(--font-family) !important;
}

/* Target tooltip text */
.MuiTooltip-tooltip {
    font-family: var(--font-family) !important;
    font-size: 0.75rem;
}

.pricing-card:hover {
    transform: translateY(-12px);
    box-shadow: 0 15px 35px rgba(63, 5, 5, 0.15);
    border-color: rgba(63, 5, 5, 0.2);
}

.pricing-title {
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
    color: #333;
    font-family: var(--font-family);
    font-size: 1.8rem;
    padding-top: 10px;
}

.pricing-price-container {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    padding: 20px 0;
    background-color: rgba(63, 5, 5, 0.03);
    border-radius: 8px;
    margin: 0 15px 30px;
}

.pricing-price {
    font-weight: 700;
    color: #333;
    font-family: var(--font-family);
    font-size: 2.5rem;
    line-height: 1.2;
}

.pricing-price.discounted {
    text-decoration: line-through;
    color: #999;
    font-size: 1.2rem;
    margin-right: 8px;
    display: block;
    margin-bottom: 5px;
}

.pricing-discount-chip {
    position: absolute;
    top: -10px;
    right: -10px;
    font-weight: 600;
    font-family: var(--font-family);
    background-color: var(--primary-color) !important;
    color: white !important;
    box-shadow: 0 4px 8px rgba(63, 5, 5, 0.3) !important;
    transform: rotate(5deg);
}

.pricing-coming-soon {
    text-align: center;
    margin: 24px 0;
    color: var(--primary-color);
    font-weight: 600;
    font-family: var(--font-family);
    background-color: rgba(63, 5, 5, 0.08);
    padding: 8px 16px;
    border-radius: 20px;
    display: inline-block;
}

.pricing-features {
    flex-grow: 1;
    margin-bottom: 30px;
    padding: 0 25px;
}

/* Ensure all feature text uses the primary font */
.pricing-features Typography {
    font-family: var(--font-family);
}

/* This should also work with lowercase typography (Material UI component) */
.pricing-features typography {
    font-family: var(--font-family);
}

/* Target the actual text elements inside features */
.pricing-features p,
.pricing-features span,
.pricing-features div {
    font-family: var(--font-family);
}

.pricing-feature {
    margin-bottom: 16px;
    color: #555;
    font-family: var(--font-family);
    position: relative;
    line-height: 1.5;
}

.pricing-actions {
    padding: 20px 25px;
    background-color: rgba(63, 5, 5, 0.03);
    border-top: 1px solid rgba(63, 5, 5, 0.08);
    margin-top: auto;
}

.pricing-actions button {
    background-color: var(--primary-color);
    transition: all 0.3s;
    padding: 12px 0;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: none;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(63, 5, 5, 0.2);
    height: auto;
    min-height: 48px;
    font-family: var(--font-family);
}

.pricing-actions button:hover {
    background-color: var(--primary-color-light);
    box-shadow: 0 6px 15px rgba(63, 5, 5, 0.3);
    transform: translateY(-2px);
}

.pricing-actions button:active {
    background-color: var(--primary-color-dark);
    transform: translateY(0);
}

.pricing-actions button .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Ensure button text uses the primary font */
.pricing-actions button,
.pricing-actions button span,
.MuiButton-label {
    font-family: var(--font-family) !important;
}

/* Ensure chip text uses the primary font */
.MuiChip-label {
    font-family: var(--font-family) !important;
}

.pricing-faq {
    margin-top: 100px;
    background-color: #f9f9f9;
    padding: 50px 30px;
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 0;
    border: 1px solid rgba(63, 5, 5, 0.05);
}

.pricing-faq::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
            circle at 5% 5%,
            rgba(63, 5, 5, 0.03) 0%,
            transparent 15%
        ),
        radial-gradient(
            circle at 95% 95%,
            rgba(63, 5, 5, 0.03) 0%,
            transparent 15%
        );
    z-index: -1;
    border-radius: 12px;
}

.pricing-faq-title {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 600;
    color: #333;
    font-family: var(--font-family);
    position: relative;
    padding-bottom: 15px;
}

.pricing-faq-title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 280px;
    min-width: 180px;
    height: 3px;
    background-color: var(--primary-color);
    border-radius: 3px;
}

.pricing-faq-item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(63, 5, 5, 0.1);
}

.pricing-faq-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.pricing-faq-item h6 {
    margin-bottom: 12px;
    font-weight: 600;
    color: #333;
    font-family: var(--font-family);
    font-size: 1.1rem;
}

.pricing-faq-item p {
    color: #666;
    font-family: var(--font-family);
    line-height: 1.6;
}

/* Animation classes */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 15px rgba(63, 5, 5, 0.2);
    }
    50% {
        transform: scale(1.03);
        box-shadow: 0 6px 20px rgba(63, 5, 5, 0.3);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 15px rgba(63, 5, 5, 0.2);
    }
}

@keyframes swing {
    0%,
    100% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
}

.fade-in {
    animation: fadeIn 0.5s ease-out forwards;
}

.pulse {
    animation: pulse 2s infinite;
}

.swing {
    animation: swing 1.5s infinite;
}

.delay-1 {
    animation-delay: 0.1s;
}

.delay-2 {
    animation-delay: 0.2s;
}

.delay-3 {
    animation-delay: 0.3s;
}

@media (max-width: 960px) {
    .pricing-page {
        padding: 60px 0;
    }

    .pricing-header {
        margin-bottom: 50px;
    }

    .pricing-grid {
        margin-bottom: 60px;
    }

    .pricing-card {
        margin-bottom: 30px;
    }

    .pricing-faq {
        padding: 40px 20px;
        margin-top: 60px;
    }
}

@media (max-width: 600px) {
    .pricing-main-title {
        font-size: 2rem;
    }

    .pricing-faq {
        padding: 30px 15px;
    }

    .pricing-price {
        font-size: 2rem;
    }
}

/* Target the most popular banner */
.pricing-card .MuiBox-root[sx*="position: absolute"] {
    font-family: var(--font-family) !important;
}

/* Popular banner text */
.pricing-card .MuiBox-root[sx*="position: absolute"] .MuiTypography-root,
.pricing-card .MuiBox-root[sx*="position: absolute"] > * {
    font-family: var(--font-family) !important;
}

/* Coming soon text */
.pricing-coming-soon,
.pricing-card .MuiBox-root[sx*="textAlign: center"] .MuiTypography-root {
    font-family: var(--font-family) !important;
}
