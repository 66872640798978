.nf-newsfeed-container {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 20px;
    font-size: 15px;
}

.nf-newsfeed-main {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 24px;
}

.nf-newsfeed-grid {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    gap: 9px;
    max-width: 100%;
}

.nf-section-title {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #454545;
    line-height: 40px;
    margin: 0;
}

.nf-section-subtitle {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #454545;

    margin-top: 0;
}
/* Feed Cards */
.nf-feed-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 18px;
    overflow: hidden;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.nf-card-header {
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #eee;
}

.nf-header-content {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex: 1;
}

.nf-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    flex-shrink: 0;
}

.nf-avatar:hover {
    transform: scale(1.05);
}

.nf-avatar-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nf-header-text {
    min-width: 0;
    flex: 1;
}

.nf-header-text h3 {
    font-weight: 600;
    margin: 0;
    word-wrap: break-word;
    line-height: 1.4;
    margin-bottom: 6px;
    font-size: 16px;
}

.nf-subtitle {
    color: var(--primary-color);
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    transition: opacity 0.2s ease-in-out;
}

.nf-subtitle:hover {
    opacity: 0.8;
}

.nf-badge {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 3px 7px;
    border-radius: 4px;
    background: #e0e0e0;
    font-size: 13px;
    margin-left: 12px;
    flex-shrink: 0;
}

.nf-card-content {
    padding: 14px;
    font-size: 14px;
}

.nf-card-content p {
    line-height: 1.6;
    margin: 0;
    margin-bottom: 16px;
}

.nf-card-content-text {
    margin-bottom: 16px;
}

.nf-card-content-text > p:not(:last-child) {
    margin-bottom: 16px;
}

.nf-card-content p:last-child {
    margin-bottom: 0;
}

.nf-card-content br {
    display: block;
    content: "";
    margin-top: 8px;
}

.nf-content-image {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 350px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.nf-card-footer {
    padding: 10px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
    font-size: 14px;
}

.nf-interaction-buttons {
    display: flex;
    align-items: center;
    gap: 16px;
}

.nf-like-button {
    display: flex;
    align-items: center;
    gap: 6px;
    background: none;
    border: none;
    padding: 6px 10px;
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.2s ease;
    color: #666;
}

.nf-like-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.nf-like-icon {
    display: flex;
    align-items: center;
}

.nf-like-icon svg {
    font-size: 20px;
}

.nf-like-count {
    font-size: 14px;
    color: #666;
}

.nf-like-button.nf-liked .nf-like-count {
    color: #ff4081;
}

/* Sidebar Cards */
.nf-sidebar-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 5px 14px;
    padding-bottom: 14px;
    margin-bottom: 29px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -9px;
}

.nf-sidebar-card h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 14px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
}

.nf-marketplace-image {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: cover;
}

.nf-marketplace-items-container {
    position: relative;
    height: 330px;
    overflow: hidden;
}

.nf-marketplace-items-wrapper {
    position: absolute;
    width: 100%;
    transition: transform 0.8s ease-in-out;
    padding-bottom: 10px;
}

.nf-marketplace-item {
    min-height: 100px;
    height: auto;
    display: flex;
    gap: 12px;
    padding: 10px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 8px;
    background: white;
    border-bottom: none;
    margin-bottom: 10px;
    align-items: stretch;
}

.nf-marketplace-item:hover {
    background-color: #f8f8f8;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.nf-marketplace-item:active {
    transform: translateY(0);
    box-shadow: none;
}

.nf-marketplace-item:last-child {
    border-bottom: none;
}

.nf-marketplace-item-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    justify-content: space-between;
}

.nf-marketplace-item-content h4 {
    margin: 0 0 4px 0;
    font-size: 15px;
    font-weight: 600;
    color: #333;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nf-marketplace-item-content .nf-lab-name {
    color: #666;
    font-size: 13px;
    margin: 0 0 auto 0;
}

.nf-price {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 14px;
    margin: auto 0 0 0;
    align-self: flex-start;
}

.nf-listing-price {
    font-size: 14px;
    margin: auto 0 0 0;
    align-self: flex-start;
}

.nf-listing-price span {
    color: var(--primary-color);
}

.nf-listing-price span.nf-listing-unit {
    color: #666;
    font-weight: normal;
}

.nf-suggestion-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nf-suggestion-item {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
}

.nf-suggestion-item:last-child {
    margin-bottom: 0;
}

.nf-suggestion-content .name {
    font-weight: 500;
    margin: 0 0 4px 0;
}

.nf-suggestion-content .details {
    color: #666;
    font-size: 14px;
    margin: 0;
}

.nf-add-button {
    position: fixed;
    bottom: 28px;
    right: 28px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(124, 58, 237, 0.3);
    transition: all 0.2s ease-in-out;
}

.nf-add-button:hover {
    background: #6d28d9;
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(124, 58, 237, 0.4);
}

.nf-marketplace-title {
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
}

.nf-marketplace-title:hover {
    color: var(--primary-color);
}

.nf-lab-avatar {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
}

.nf-lab-avatar img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nf-suggestion-item {
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.nf-suggestion-item:hover {
    background-color: #f8f8f8;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.nf-suggestion-item:active {
    transform: translateY(0);
    box-shadow: none;
}

.nf-lab-title {
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 4px 0;
    color: #333;
}

.nf-lab-details {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.nf-lab-details span {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #666;
    font-size: 13px;
}

.nf-connection-avatar {
    width: 44px;
    height: 44px;
    overflow: hidden;
    position: relative;
    background-color: #d0d0d0;
    border-radius: 50%;
    flex-shrink: 0;
}

.nf-connection-avatar::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M12 11.5c2.07 0 3.75-1.68 3.75-3.75S14.07 4 12 4 8.25 5.68 8.25 7.75 9.93 11.5 11.5zm0 2c-3.17 0-6.75 1.53-6.75 4.5v2h13.5v-2c0-2.97-3.58-4.5-6.75-4.5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.6;
    pointer-events: none;
    z-index: 1;
}

.nf-connection-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 2;
}

.nf-connection-avatar img[src=""],
.nf-connection-avatar img[src="undefined"],
.nf-connection-avatar img:not([src]) {
    display: none;
}

.nf-connection-avatar-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
}

.nf-connection-avatar-placeholder svg {
    width: 24px;
    height: 24px;
    color: #666;
}

.nf-connection-name {
    font-weight: 600;
    font-size: 15px;
    margin: 0 0 4px 0;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nf-connection-role {
    color: var(--primary-color);
    font-size: 12px;
    margin: 0 0 2px 0;
}

.nf-connection-field {
    color: #666;
    font-size: 12px;
    margin: 0;
}

.nf-suggestion-item {
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
}

.nf-suggestion-item:hover {
    background-color: #f5f5f5;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.nf-connection-lab {
    color: var(--primary-color);
    font-size: 13px;
    margin: 0 0 2px 0;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nf-connection-institution {
    color: #666;
    font-size: 13px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nf-author-info {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
}

.nf-lab-name {
    color: #666;
    font-style: italic;
    font-size: 14px;
    transition: color 0.2s ease-in-out;
    margin-bottom: 0;
}

.nf-lab-name:hover {
    color: var(--primary-color);
}

.nf-card-content p {
    line-height: 1.6;
    margin: 0;
}

.nf-publication-links {
    display: flex;
    gap: 16px;
}

.nf-publication-link {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 14px;
    transition: opacity 0.2s ease-in-out;
}

.nf-publication-link:hover {
    opacity: 0.8;
}

.nf-publication-meta {
    color: #666;
    font-size: 14px;
    margin-top: 8px !important;
    line-height: 1.4;
}

.nf-publication-journal {
    color: var(--primary-color);
    font-weight: 500;
    font-style: italic;
}

.nf-suggestion-content {
    max-width: 300px;
    min-width: 0;
    overflow: hidden;
}

/* Media query for tablet and smaller screens */
@media screen and (max-width: 1024px) {
    .nf-newsfeed-main {
        padding: 0 32px;
    }

    .nf-newsfeed-grid {
        gap: 16px;
    }
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
    .nf-newsfeed-container {
        padding: 12px;
    }

    .nf-newsfeed-main {
        padding: 0 12px;
    }

    .nf-newsfeed-grid {
        grid-template-columns: 1fr; /* Single column for mobile */
    }

    /* Hide sidebar cards on mobile */
    .nf-sidebar-column {
        display: none;
    }

    /* Adjust feed card padding for mobile */
    .nf-feed-card {
        margin-bottom: 16px;
    }

    .nf-card-header {
        padding: 12px;
    }

    .nf-card-content {
        padding: 12px;
    }

    .nf-card-footer {
        padding: 12px;
    }
}

/* Adjust max-width breakpoints for larger screens */
@media screen and (min-width: 1400px) {
    .nf-newsfeed-main {
        max-width: 1300px;
    }
}

.nf-content-link {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 500;
}

.nf-carousel-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nf-carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    transition: background 0.3s ease;
    padding: 0;
}

.nf-carousel-arrow:hover {
    background: rgba(0, 0, 0, 0.7);
}

.nf-carousel-arrow.nf-left {
    left: 10px;
}

.nf-carousel-arrow.nf-right {
    right: 10px;
}

.nf-carousel-arrow svg {
    font-size: 20px;
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
    .nf-carousel-arrow {
        width: 32px;
        height: 32px;
    }

    .nf-carousel-arrow svg {
        font-size: 18px;
    }
}

.nf-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 20px;
    color: #666;
    margin-bottom: 20px;
    min-height: 40px;
}

.nf-loading > * {
    opacity: 0;
    transition: opacity 0.2s ease;
}

.nf-loading.visible > * {
    opacity: 1;
}

.nf-no-more {
    text-align: center;
    padding: 20px;
    color: #666;
}

/* Chatbox Styles */
.nf-chatbox {
    position: fixed;
    bottom: 100px;
    right: 30px;
    width: 400px;
    height: 550px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: nf-slide-up 0.3s ease-out forwards;
    z-index: 1000;
}

.nf-chatbox-maximized {
    width: calc(100% - 20px);
    height: calc(90% - 30px);
    bottom: 0;
    right: 10px;
    left: 10px;
    top: 30px;
    margin: auto;
    max-width: none;
    border-radius: 8px;
}

@keyframes nf-slide-up {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.nf-chatbox-header {
    padding: 12px 16px;
    background: var(--primary-color, #5270ff);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.nf-chatbox-title {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-weight: 600;
}

.nf-chatbox-title svg {
    color: white;
}

.nf-chatbox-controls {
    display: flex;
    gap: 8px;
}

.nf-chatbox-maximize,
.nf-chatbox-close {
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s;
}

.nf-chatbox-maximize:hover,
.nf-chatbox-close:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.nf-chatbox-maximize svg,
.nf-chatbox-close svg {
    font-size: 20px;
}

.nf-chatbox-messages {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #f9f9f9;
}

.nf-chatbox-maximized .nf-chat-message {
    max-width: 60%;
}

.nf-chat-message {
    padding: 12px 16px;
    border-radius: 12px;
    max-width: 85%;
    line-height: 1.5;
    position: relative;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.nf-user-message {
    align-self: flex-end;
    background-color: var(--primary-color, #5270ff);
    color: white;
    border-bottom-right-radius: 4px;
}

.nf-ai-message {
    align-self: flex-start;
    background-color: #f0f0f0;
    color: #333;
    border-bottom-left-radius: 4px;
}

.nf-chatbox-input {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
}

.nf-input-container {
    display: flex;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 10px;
    flex-direction: column;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.nf-message-area {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
}

.nf-search-toggles {
    display: flex;
    gap: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 8px;
    margin-top: 2px;
    justify-content: flex-start;
}

.nf-search-toggle {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 13px;
    background-color: transparent;
    color: #777;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
    flex: 0 0 auto;
    justify-content: center;
}

.nf-search-toggle svg {
    margin-right: 5px;
    font-size: 16px;
}

.nf-search-toggle:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #555;
}

.nf-search-toggle.active {
    background-color: rgba(82, 112, 255, 0.1);
    color: var(--primary-color, #5270ff);
}

.nf-search-toggle.active svg {
    color: var(--primary-color, #5270ff);
}

.nf-chatbox-input input {
    flex: 1;
    border: none;
    padding: 10px;
    font-size: 14px;
    background-color: transparent;
    outline: none;
    color: #333;
}

.nf-chatbox-input input::placeholder {
    color: #999;
}

.nf-chatbox-input button[type="submit"] {
    background-color: transparent;
    border: none;
    color: var(--primary-color, #5270ff);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    padding: 8px;
    border-radius: 8px;
}

.nf-chatbox-input button[type="submit"]:hover:not(:disabled) {
    background-color: rgba(82, 112, 255, 0.1);
}

.nf-chatbox-input button[type="submit"]:disabled {
    color: #ccc;
    cursor: not-allowed;
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
    .nf-chatbox {
        width: calc(100% - 32px);
        height: 450px;
        bottom: 80px;
        right: 16px;
        left: 16px;
    }

    .nf-chatbox-maximized {
        width: 100%;
        height: 100%;
        max-width: none;
        border-radius: 0;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        margin: 0;
    }

    .nf-chatbox-maximized .nf-chat-message {
        max-width: 85%;
    }

    .nf-chatbox-maximized .nf-chat-input-container {
        padding: 10px;
    }
}

/* Media query for extra small screens */
@media screen and (max-width: 480px) {
    .nf-chatbox-maximized .nf-chat-message {
        max-width: 90%;
    }

    .nf-search-toggle {
        padding: 4px 8px;
        font-size: 12px;
    }

    .nf-search-toggle svg {
        font-size: 14px;
    }
}

/* Keep the new functionality styles */
/* Chat typing indicator */
.nf-typing-indicator {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    min-height: 20px;
    background-color: #f0f0f0;
    margin-bottom: 10px;
}

.nf-typing-text {
    font-size: 14px;
    color: #555;
    margin-right: 8px;
    font-weight: 500;
}

.nf-typing-indicator .nf-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #aaa;
    animation: typing-animation 1.4s infinite ease-in-out;
}

.nf-typing-indicator .nf-dot:nth-child(0) {
    animation-delay: 0s;
}

.nf-typing-indicator .nf-dot:nth-child(1) {
    animation-delay: 0.2s;
}

.nf-typing-indicator .nf-dot:nth-child(2) {
    animation-delay: 0.4s;
}

@keyframes typing-animation {
    0%,
    60%,
    100% {
        transform: translateY(0);
        opacity: 0.6;
    }
    30% {
        transform: translateY(-4px);
        opacity: 1;
    }
}

/* Error message styling */
.nf-error-message {
    background-color: rgba(255, 0, 0, 0.1);
    border-left: 3px solid #ff5252;
}

/* Disabled button styling */
.nf-chatbox-input button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Message text styling */
.nf-message-text {
    margin-bottom: 8px;
    white-space: pre-wrap;
    line-height: 1.5;
    font-size: 14px;
    color: #333;
}

.nf-user-message .nf-message-text {
    color: white;
}

/* Search results styling */
.nf-search-results {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 8px;
}

.nf-search-results::before {
    content: "Search Results";
    display: block;
    font-weight: 600;
    margin-bottom: 12px;
    color: #555;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.nf-search-result-item {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    position: relative;
}

.nf-search-result-item:hover {
    background-color: #f0f7ff;
    border-color: var(--primary-color);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nf-search-result-item:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nf-search-result-item::after {
    content: "→";
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color);
    font-size: 18px;
    opacity: 0.7;
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.nf-search-result-item:hover::after {
    opacity: 1;
    transform: translate(3px, -50%);
}

.nf-search-result-item h4 {
    margin: 0 0 8px 0;
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
    padding-right: 20px;
}

.nf-result-relevance {
    margin: 0 0 8px 0;
    font-size: 13px;
    color: #555;
    font-style: italic;
    line-height: 1.4;
}

.nf-result-highlights {
    margin: 0;
    font-size: 13px;
    color: #444;
    line-height: 1.5;
}

/* Preserve markdown styling */
.nf-message-text strong {
    font-weight: 600;
}

.nf-message-text em {
    font-style: italic;
}

.nf-message-text ul,
.nf-message-text ol {
    margin-left: 20px;
    margin-bottom: 10px;
    padding-left: 10px;
}

.nf-message-text ul li,
.nf-message-text ol li {
    margin-bottom: 6px;
}

.nf-message-text p {
    margin-bottom: 12px;
}

.nf-message-text h1,
.nf-message-text h2,
.nf-message-text h3,
.nf-message-text h4,
.nf-message-text h5,
.nf-message-text h6 {
    margin-top: 16px;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 1.3;
}

.nf-message-text h1 {
    font-size: 20px;
}

.nf-message-text h2 {
    font-size: 18px;
}

.nf-message-text h3 {
    font-size: 16px;
}

.nf-message-text h4,
.nf-message-text h5,
.nf-message-text h6 {
    font-size: 15px;
}

.nf-message-text code {
    background-color: #f1f1f1;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
    font-size: 13px;
}

.nf-message-text pre {
    background-color: #f5f5f5;
    padding: 12px;
    border-radius: 6px;
    overflow-x: auto;
    margin-bottom: 12px;
}

.nf-message-text pre code {
    background-color: transparent;
    padding: 0;
    font-size: 13px;
    line-height: 1.5;
}

.nf-message-text blockquote {
    border-left: 3px solid #d0d0d0;
    padding-left: 12px;
    margin-left: 0;
    margin-right: 0;
    color: #555;
    font-style: italic;
}

.nf-message-text a {
    color: var(--primary-color);
    text-decoration: underline;
    transition: color 0.2s ease;
}

.nf-message-text a:hover {
    color: #6d28d9;
}

.nf-message-text table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 12px;
}

.nf-message-text th,
.nf-message-text td {
    border: 1px solid #e0e0e0;
    padding: 8px;
    text-align: left;
}

.nf-message-text th {
    background-color: #f5f5f5;
    font-weight: 600;
}

/* Improve the AI message that contains search results */
.nf-ai-message.nf-chat-message:has(.nf-search-results) {
    max-width: 90%;
    padding: 16px;
}

/* Ensure the AI message with search results has proper spacing */
.nf-ai-message.nf-chat-message.isSearchResults {
    padding: 16px;
    background-color: #f8f8f8;
}

/* Clickable title styling */
.nf-clickable-title {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s ease;
    border-bottom: 1px dashed var(--primary-color);
    display: inline-block;
    margin-bottom: 2px;
    position: relative;
    background-color: rgba(124, 58, 237, 0.05);
    padding: 2px 6px;
    border-radius: 4px;
}

.nf-clickable-title:hover {
    color: #6d28d9;
    border-bottom: 1px solid #6d28d9;
    text-decoration: none;
    background-color: rgba(124, 58, 237, 0.1);
    box-shadow: 0 1px 3px rgba(124, 58, 237, 0.2);
}

/* Fix line breaks in messages */
.nf-message-text br {
    display: block;
    content: "";
    margin-top: 8px;
}

/* Ensure proper spacing between paragraphs */
.nf-message-text p + p {
    margin-top: 8px;
}

.nf-search-description {
    margin: 8px 0 8px 24px;
    padding: 6px 0;
    color: #444;
    line-height: 1.5;
    position: relative;
    font-size: 14px;
}

.nf-search-description:before {
    content: "•";
    position: absolute;
    left: -16px;
    color: var(--primary-color);
    font-weight: bold;
}

.nf-search-description strong {
    color: #333;
    font-weight: 600;
}

.nf-search-description em {
    font-style: italic;
}

.nf-search-relevance {
    margin: 4px 0 4px 24px;
    padding: 4px 0;
    color: #555;
    line-height: 1.4;
    position: relative;
    font-size: 13px;
    font-style: italic;
}

.nf-search-relevance:before {
    content: "Relevance: ";
    font-weight: 600;
    color: #444;
    font-style: normal;
}

.nf-search-highlights {
    margin: 4px 0 12px 24px;
    padding: 4px 0;
    color: #444;
    line-height: 1.5;
    position: relative;
    font-size: 13px;
}

.nf-search-highlights:before {
    content: "Highlights: ";
    font-weight: 600;
    color: #444;
}

/* Ensure proper spacing between search results */
.nf-clickable-title {
    margin-bottom: 6px;
    display: inline-block;
}

/* Search query list styling */
.nf-search-query-list {
    list-style: none;
    padding: 0;
    margin: 4px 0 8px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.nf-search-query-list li {
    background-color: #f0f0f0;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    padding: 4px 12px;
    font-size: 13px;
    color: #444;
    display: inline-block;
    transition: all 0.2s ease;
}

.nf-search-query-list li:hover {
    background-color: #e8e8ff;
    border-color: var(--primary-color);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Ensure proper spacing for search query section */
.nf-ai-message .nf-message-text strong + ul.nf-search-query-list {
    margin-top: 6px;
    margin-bottom: 6px;
}

/* Add these styles to your existing Newsfeed.css */

.nf-feed-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-direction: column;
    border-bottom: 1px solid #d0d0d0;
}

.nf-post-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nf-post-button:hover:not(.disabled) {
    background-color: #6d28d9;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.nf-post-button.disabled {
    background-color: #d1d5db;
    cursor: not-allowed;
    opacity: 0.8;
}

.nf-post-button svg {
    font-size: 20px;
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
    .nf-feed-header {
        flex-direction: column;
        gap: 16px;
    }

    .nf-post-button {
        width: 100%;
        justify-content: center;
    }
}

/* News Editor Styles */
.news-item-editor {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

.news-item-editor input[type="text"] {
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
}

.textarea-container {
    position: relative;
    width: 100%;
}

.news-item-editor textarea {
    width: 100%;
    min-height: 200px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
    resize: vertical;
}

.add-link-button {
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px 12px;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.add-link-button:hover {
    background-color: #e0e0e0;
}

.news-item-image-upload {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 12px;
    margin-top: 16px;
}

.news-item-image-preview {
    aspect-ratio: 1;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    position: relative;
}

.delete-image-button {
    position: absolute;
    top: 4px;
    right: 4px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: all 0.2s ease;
}

.delete-image-button:hover {
    background: rgba(0, 0, 0, 0.7);
}

.image-upload-placeholder {
    border: 2px dashed #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #666;
}

.image-upload-placeholder:hover {
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.link-modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

.link-modal-content input {
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
}

.nf-create-post {
    background: white;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.nf-create-post:not(.disabled):hover {
    background-color: #f8f8f8;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.nf-create-post.disabled {
    cursor: not-allowed;
    opacity: 0.8;
    background-color: #f0f0f0;
}

.nf-post-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
}

.nf-post-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nf-post-avatar-placeholder {
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nf-post-input {
    flex: 1;
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 12px 16px;
    min-height: 40px;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease;
}

.nf-create-post:not(.disabled):hover .nf-post-input {
    background-color: #ececec;
}

.nf-post-placeholder {
    color: #666;
    font-size: 14px;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
    .nf-create-post {
        padding: 10px 12px;
    }

    .nf-post-avatar {
        width: 32px;
        height: 32px;
    }

    .nf-post-input {
        padding: 10px 14px;
        min-height: 36px;
    }
}
