.lab-transfer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 40px 20px;
    font-family: var(--font-family);
}

.lab-transfer-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 800px;
    padding: 40px;
}

.lab-transfer-page-title {
    font-size: 28px;
    font-weight: 600;
    color: #1a202c;
    margin: 0 0 30px 0;
    text-align: center;
}

/* Lab Info Section */
.lab-transfer-info-section {
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e2e8f0;
}

.lab-transfer-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.lab-transfer-logo {
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 12px;
    background-color: #f0f9ff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.lab-transfer-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lab-transfer-logo-overlay {
    position: absolute;
    color: #3182ce;
}

.lab-transfer-title h2 {
    font-size: 24px;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
}

.lab-transfer-description {
    color: #4a5568;
    line-height: 1.6;
}

.lab-transfer-content ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin: 1rem 0;
}

.lab-transfer-content ol {
    list-style-type: decimal;
    padding-left: 2rem;
    margin: 1rem 0;
}

.lab-transfer-content li {
    margin: 0.5rem 0;
}

/* Transfer Visualization */
.lab-transfer-visualization {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}

.lab-transfer-from,
.lab-transfer-to {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.lab-transfer-user-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a5568;
}

.lab-transfer-user-circle.highlight {
    background-color: rgba(63, 05, 05, 0.1);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.lab-transfer-arrow {
    margin: 0 40px;
    color: var(--primary-color);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Form Section */
.lab-transfer-form-section {
    margin-top: 40px;
}

.lab-transfer-form-section h3 {
    font-size: 20px;
    font-weight: 600;
    color: #2d3748;
    margin: 0 0 10px 0;
}

.lab-transfer-form-description {
    color: #718096;
    margin-bottom: 30px;
}

.lab-transfer-form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.lab-transfer-form-group {
    margin-bottom: 20px;
    flex: 1;
}

.lab-transfer-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #4a5568;
}

.lab-transfer-input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 16px;
    transition: all 0.2s;
}

.lab-transfer-input:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.lab-transfer-password-group {
    position: relative;
}

.lab-transfer-password-input {
    position: relative;
}

.lab-transfer-toggle-password {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #718096;
    font-size: 14px;
    cursor: pointer;
}

.lab-transfer-password-hint {
    font-size: 14px;
    color: #718096;
    margin-top: 8px;
}

.lab-transfer-form-footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.lab-transfer-submit-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.lab-transfer-submit-button:hover {
    background-color: #2c5282;
}

/* Success Message */
.lab-transfer-success-message {
    text-align: center;
    padding: 40px 20px;
}

.lab-transfer-success-icon {
    color: #38a169;
    margin-bottom: 20px;
}

.lab-transfer-success-message h2 {
    font-size: 24px;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 16px;
}

.lab-transfer-success-message p {
    color: #4a5568;
    margin-bottom: 30px;
    line-height: 1.6;
}

.lab-transfer-return-button {
    background-color: #3182ce;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.lab-transfer-return-button:hover {
    background-color: #2c5282;
}

/* Options container for success message buttons */
.lab-transfer-options {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
}

/* Sign in button styling */
.lab-transfer-signin-button {
    background-color: white;
    color: #3182ce;
    border: 1px solid #3182ce;
    border-radius: 6px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.lab-transfer-signin-button:hover {
    background-color: #ebf8ff;
}

/* Responsive */
@media (max-width: 768px) {
    .lab-transfer-card {
        padding: 30px 20px;
    }

    .lab-transfer-form-row {
        flex-direction: column;
        gap: 0;
    }

    .lab-transfer-arrow {
        margin: 0 20px;
    }
}

/* Form Error Styling */
.lab-transfer-error {
    color: #e53e3e;
    font-size: 14px;
    margin-top: 6px;
    display: block;
}

.lab-transfer-input[aria-invalid="true"] {
    border-color: #e53e3e;
}

.lab-transfer-input[aria-invalid="true"]:focus {
    border-color: #e53e3e;
    box-shadow: 0 0 0 3px rgba(229, 62, 62, 0.1);
}

/* Disabled input styling */
.lab-transfer-input:disabled {
    background-color: #f7fafc;
    color: #718096;
    cursor: not-allowed;
}

/* Form validation success indicators */
.lab-transfer-input.is-valid {
    border-color: #38a169;
}

.lab-transfer-input.is-valid:focus {
    border-color: #38a169;
    box-shadow: 0 0 0 3px rgba(56, 161, 105, 0.1);
}

/* Loading state for submit button */
.lab-transfer-submit-button.loading {
    position: relative;
    color: transparent;
}

.lab-transfer-submit-button.loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top-color: white;
    animation: spin 0.8s linear infinite;
}

.lab-transfer-button-note {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-top: 4px;
    color: #718096;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .lab-transfer-options {
        flex-direction: column;
        gap: 12px;
    }

    .lab-transfer-return-button,
    .lab-transfer-signin-button {
        width: 100%;
    }
}
