.modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
    overflow: hidden;
}

.modal-title {
    text-align: center;
    margin-top: 0;
    padding-top: 20px;
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.modal-content {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 900px;
    min-width: auto;
    max-height: 85vh;
    min-height: 25vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overscroll-behavior: contain; /* Prevents scroll chaining */
    /* Isolate scrolling within the modal and its children */
    isolation: isolate;
}

.modal-body {
    display: flex;
    width: 100%;
    flex: 1;
    padding: 0 20px;
    overflow-y: auto;
    overscroll-behavior: contain; /* Prevents scroll chaining */
    /* Enhance scroll containment */
    touch-action: pan-y;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    z-index: 1000;
    font-size: 20px;
}

.modal-footer {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px 20px;
    gap: 10px;
}

.modal-submit-button {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
}

.modal-cancel-button {
    padding: 10px 20px;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.modal-no-scroll {
    overflow: hidden;
}

/* Prevent body scroll when modal is open */
body.modal-open {
    overflow: hidden;
    height: 100%;
    position: fixed;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .modal-content {
        padding: 15px;
        margin: 15px;
        width: 95%;
    }

    .modal-title {
        font-size: 20px;
    }

    .modal-body {
        padding: 10px 0;
    }

    .modal-close-button {
        top: 5px;
        right: 5px;
    }
}

@media screen and (max-width: 480px) {
    .modal-content {
        padding: 10px;
        margin: 10px;
        width: 98%;
    }
}

.MuiAutocomplete-popper {
    z-index: 10001 !important;
}
