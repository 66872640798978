/* Lab Assistant Chat container styles */
.lab-assistant-chat-container {
    display: flex;
    height: calc(100vh - 64px); /* 64px is the standard height of the navbar */
    width: 100%;
    overflow: hidden;
    position: relative;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif;
    background-color: #f0f1f7;
}

/* Sidebar styles */
.lab-assistant-chat-sidebar {
    width: 260px;
    background-color: #ffffff;
    border-right: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    z-index: 110; /* Make sure sidebar is above the input container */
    position: relative; /* Ensure z-index works properly */
}

.lab-assistant-chat-sidebar-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
}

.lab-assistant-chat-sidebar-header h2 {
    margin: 0;
    color: #7c3aed;
    font-size: 18px;
    font-weight: 600;
}

.lab-assistant-chat-new-btn {
    background-color: #7c3aed;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.lab-assistant-chat-new-btn:hover {
    background-color: #6d28d9;
}

.lab-assistant-chat-sidebar-search {
    padding: 12px 16px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    position: relative;
}

.lab-assistant-chat-search-icon {
    position: absolute;
    left: 22px;
    color: #9ca3af;
    font-size: 18px;
}

.lab-assistant-chat-sidebar-search input {
    width: 100%;
    padding: 8px 12px 8px 32px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f5f5f5;
}

.lab-assistant-chat-sidebar-search input:focus {
    outline: none;
    border-color: #7c3aed;
    background-color: white;
}

.lab-assistant-chat-threads {
    flex: 1;
    overflow-y: auto;
    padding: 8px;
    padding-bottom: 170px; /* Add padding to ensure bottom content is visible */
}

.lab-assistant-chat-thread-group {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
}

.lab-assistant-chat-thread-group:last-child {
    border-bottom: none;
}

.lab-assistant-chat-thread-group-title {
    font-size: 12px;
    color: #666;
    text-transform: none;
    margin: 12px 8px 4px;
    font-weight: 600;
    letter-spacing: 0.3px;
}

.lab-assistant-chat-thread {
    position: relative;
    padding: 8px 12px;
    border-radius: 6px;
    margin-bottom: 2px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 1px solid transparent;
}

.lab-assistant-chat-thread:hover {
    background-color: #f5f7fd;
}

.lab-assistant-chat-thread.active {
    background-color: #f0f3ff;
    border-color: #d1d8fb;
}

.lab-assistant-chat-thread-content {
    flex: 1;
    min-width: 0; /* Ensures flex item can shrink below its content size */
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: -1px;
}

.lab-assistant-chat-thread-title {
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #111827;
    margin-bottom: 0;
    padding: 0;
    line-height: 1.2;
}

.lab-assistant-chat-thread-time {
    font-size: 11px;
    color: #9ca3af;
    margin-top: 2px;
    line-height: 1;
}

.lab-assistant-chat-thread-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    flex-shrink: 0;
    position: relative;
    align-self: flex-start;
}

/* Menu button styles */
.lab-assistant-chat-thread-menu-btn {
    background: none;
    border: none;
    color: #9ca3af;
    cursor: pointer;
    padding: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease, background-color 0.2s ease, color 0.2s ease;
    height: 22px;
    width: 22px;
    position: relative;
}

.lab-assistant-chat-thread:hover .lab-assistant-chat-thread-menu-btn {
    opacity: 1;
}

.lab-assistant-chat-thread-menu-btn:hover {
    background-color: rgba(124, 58, 237, 0.1);
    color: #7c3aed;
}

/* Old delete button style - keeping for reference */
.lab-assistant-chat-thread-delete {
    background: none;
    border: none;
    color: #9ca3af;
    cursor: pointer;
    padding: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease, background-color 0.2s ease, color 0.2s ease;
    height: 22px;
    width: 22px;
}

.lab-assistant-chat-thread:hover .lab-assistant-chat-thread-delete {
    opacity: 1;
}

.lab-assistant-chat-thread-delete:hover {
    background-color: rgba(239, 68, 68, 0.1);
    color: rgb(239, 68, 68);
}

/* Dropdown menu styles */
.lab-assistant-chat-thread-dropdown {
    position: fixed; /* Positioned by JavaScript */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    width: 180px;
    z-index: 9999; /* Ensure it's above everything */
    overflow: hidden;
    animation: fadeInScale 0.15s ease forwards;
    transform-origin: top left;
    padding: 8px 0;
    border: 1px solid #e5e7eb;
}

@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.lab-assistant-chat-thread-dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    width: 100%;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s;
    color: #374151;
    font-size: 14px;
    gap: 8px;
}

.lab-assistant-chat-thread-dropdown-item:hover {
    background-color: #f3f4f6;
}

.lab-assistant-chat-thread-dropdown-item svg {
    color: #6b7280;
    font-size: 17px !important;
}

.lab-assistant-chat-thread-dropdown-delete {
    color: #ef4444 !important;
}

.lab-assistant-chat-thread-dropdown-delete svg {
    color: #ef4444 !important;
}

.lab-assistant-chat-thread-dropdown-delete:hover {
    background-color: rgba(239, 68, 68, 0.1);
}

.lab-assistant-chat-empty {
    text-align: center;
    padding: 20px;
    color: #6b7280;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.lab-assistant-chat-empty p {
    margin: 5px 0;
    font-size: 14px;
}

.lab-assistant-chat-empty p:first-child {
    font-weight: 500;
    color: #4b5563;
}

.lab-assistant-chat-loading {
    text-align: center;
    padding: 20px;
    color: #6b7280;
    font-style: italic;
}

.lab-assistant-chat-user-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-top: 1px solid #eaeaea;
    font-size: 14px;
    color: #4b5563;
    height: 100%;
}

.lab-assistant-chat-sidebar-footer {
    padding: 10px 16px;
    border-top: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
}

.lab-assistant-chat-login-btn {
    background-color: #7c3aed;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lab-assistant-chat-login-btn:hover {
    background-color: #6d28d9;
}

/* Main chat area styles */
.lab-assistant-chat-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    background-color: #f7f8fc;
    background-image: linear-gradient(to bottom, #f0f2fa, #fbfcff);
    position: relative;
    gap: 0 !important;
}

/* Set a consistent container for both message and input areas */
.lab-assistant-chat-input-container,
.lab-assistant-chat-messages-container {
    padding-left: 15%;
    padding-right: 15%;
}

.lab-assistant-chat-messages-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: min-content;
    width: 70%; /* Fixed width percentage */
    max-width: 800px; /* Max width */
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.lab-assistant-chat-messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-behavior: auto;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 0 !important; /* Force no bottom margin */
}

/* Change position from fixed to relative for proper flex layout */
.lab-assistant-chat-input-container {
    position: relative;
    background-color: rgba(247, 248, 252, 0.9);
    background-image: linear-gradient(
        to bottom,
        rgba(247, 248, 252, 0.8),
        #f7f8fc
    );
    backdrop-filter: blur(5px);
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.lab-assistant-chat-input-container form {
    width: 70%; /* Fixed width percentage - same as messages */
    max-width: 800px; /* Max width - same as messages */
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Force exact same width for both elements */
.lab-assistant-chat-input-wrapper,
.lab-assistant-chat-ai-message .lab-assistant-chat-message-content {
    max-width: 100%;
    width: 100%;
}

.lab-assistant-chat-input-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #e6e8ef;
    border-radius: 12px;
    padding: 12px 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    width: 100%;
}

.lab-assistant-chat-input-controls {
    display: flex;
    align-items: center;
    width: 100%;
}

.lab-assistant-chat-input-wrapper input {
    display: none; /* Hide the original input */
}

.lab-assistant-chat-textarea {
    flex: 1;
    border: none;
    background: transparent;
    font-size: 14.5px;
    outline: none;
    padding: 4px 0;
    color: #333;
    resize: none;
    overflow-y: auto;
    min-height: 20px;
    max-height: 150px;
    line-height: 1.6;
    transition: height 0.1s ease;
    position: relative;
    bottom: 0;
    width: 100%;
}

.lab-assistant-chat-textarea::placeholder {
    color: #999;
}

.lab-assistant-chat-input-wrapper button {
    background-color: transparent;
    border: none;
    color: #7c3aed;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s;
}

.lab-assistant-chat-input-wrapper button:hover:not(:disabled) {
    color: #6d28d9;
}

.lab-assistant-chat-input-wrapper button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

/* Stop button styling */
.lab-assistant-chat-stop-btn {
    background-color: transparent;
    border: none;
    color: #ef4444 !important;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    border-radius: 50%;
}

.lab-assistant-chat-stop-btn:hover {
    color: #dc2626 !important;
    background-color: rgba(239, 68, 68, 0.1);
}

/* Scroll to bottom button */
.lab-assistant-chat-scroll-btn {
    position: fixed;
    bottom: 120px;
    right: 16px;
    background-color: var(--primary-color, #7c3aed);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.lab-assistant-chat-scroll-btn:hover {
    transform: translateY(-2px);
    opacity: 0.9;
}

/* Welcome screen */
.lab-assistant-chat-welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 60px;
}

.lab-assistant-chat-welcome h1 {
    font-size: 28px;
    margin-bottom: 24px;
    color: #333;
}

.lab-assistant-chat-quick-actions {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 32px;
    flex-wrap: wrap;
}

.lab-assistant-chat-action-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #e6e8ef;
    cursor: pointer;
    transition: all 0.2s;
    width: 100px;
}

.lab-assistant-chat-action-btn svg {
    color: #7c3aed;
    font-size: 24px;
}

.lab-assistant-chat-action-btn span {
    font-size: 14px;
    color: #333;
}

.lab-assistant-chat-action-btn:hover {
    background-color: #f0f0f0;
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.lab-assistant-chat-sample-questions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 500px;
}

.lab-assistant-chat-sample-question {
    padding: 12px 16px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #e6e8ef;
    text-align: left;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 14px;
    color: #333;
}

.lab-assistant-chat-sample-question:hover {
    background-color: #f0f0f0;
    border-color: #7c3aed;
}

/* Chat messages */
.lab-assistant-chat-message {
    position: relative; /* Add position relative */
    z-index: 10; /* Ensure messages stay above the input */
    display: flex;
    flex-direction: column; /* Change to column to stack image and message content */
    margin-bottom: 16px;
    animation: fade-in 0.3s ease-out;
    width: 100%;
}

.lab-assistant-chat-message:last-child {
    margin-bottom: 5px !important;
}

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.lab-assistant-chat-message-content {
    padding: 10px 16px;
    border-radius: 12px;
    max-width: 80%;
    font-size: 14.5px;
    line-height: 1.6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
}

.lab-assistant-chat-ai-message {
    width: 100%;
    align-items: flex-start; /* Align AI messages to the left */
}

.lab-assistant-chat-ai-message .lab-assistant-chat-message-content {
    background-color: transparent;
    border-bottom-left-radius: 4px;
    box-shadow: none;
    max-width: 100%;
    width: 100%;
}

.lab-assistant-chat-user-message {
    justify-content: flex-end;
    align-items: flex-end; /* Align user messages to the right */
}

.lab-assistant-chat-user-message .lab-assistant-chat-message-content {
    background-color: #7c3aed;
    color: white;
    border-bottom-right-radius: 4px;
    box-shadow: 0 1px 2px rgba(124, 58, 237, 0.3);
}

.lab-assistant-chat-message-text {
    white-space: pre-wrap;
}

.lab-assistant-chat-message-text p {
    margin-top: 0;
    margin-bottom: 0.5em;
}

.lab-assistant-chat-message-text p:last-child {
    margin-bottom: 0;
}

.lab-assistant-chat-user-message .lab-assistant-chat-message-text a {
    color: #e9d5ff;
}

.lab-assistant-chat-ai-message .lab-assistant-chat-message-text a {
    color: #7c3aed;
}

/* Typing indicator */
.lab-assistant-chat-typing-indicator {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    animation: fade-in 0.3s ease-out;
}

.lab-assistant-chat-typing-dots {
    background-color: #ffffff;
    padding: 12px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    max-width: 80px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.lab-assistant-chat-typing-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #7c3aed;
    opacity: 0.6;
    animation: typing-animation 1.4s infinite ease-in-out;
}

.lab-assistant-chat-typing-dot:nth-child(1) {
    animation-delay: 0s;
}

.lab-assistant-chat-typing-dot:nth-child(2) {
    animation-delay: 0.2s;
}

.lab-assistant-chat-typing-dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes typing-animation {
    0%,
    60%,
    100% {
        transform: translateY(0);
        opacity: 0.6;
    }
    30% {
        transform: translateY(-4px);
        opacity: 1;
    }
}

/* Input area */
.lab-assistant-chat-queries-count {
    font-size: 12px;
    color: #666;
    text-align: right;
    padding-right: 4px;
}

.lab-assistant-chat-model-select {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 0;
    border-top: 1px solid #e6e8ef;
    margin-top: 8px;
    padding-top: 8px;
    font-size: 12px;
    color: #666;
}

.lab-assistant-chat-model-option {
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
}

.lab-assistant-chat-model-option.active {
    background-color: rgba(124, 58, 237, 0.1);
    color: #7c3aed;
    font-weight: 500;
}

.lab-assistant-chat-model-option:hover {
    background-color: #e9ebf5;
}

.lab-assistant-chat-model-select-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    cursor: pointer;
}

/* Error message */
.lab-assistant-chat-error-message .lab-assistant-chat-message-content {
    background-color: #fee2e2;
    border-left: 3px solid #ef4444;
    color: #b91c1c;
}

/* Search results styling */
.lab-assistant-chat-search-results {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 8px;
}

.lab-assistant-chat-search-results::before {
    content: "Search Results";
    display: block;
    font-weight: 600;
    margin-bottom: 12px;
    color: #555;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.lab-assistant-chat-search-result-item {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    position: relative;
}

.lab-assistant-chat-search-result-item:hover {
    background-color: #f0f7ff;
    border-color: #7c3aed;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.lab-assistant-chat-search-result-item:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lab-assistant-chat-search-result-item::after {
    content: "→";
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    color: #7c3aed;
    font-size: 18px;
    opacity: 0.7;
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.lab-assistant-chat-search-result-item:hover::after {
    opacity: 1;
    transform: translate(3px, -50%);
}

.lab-assistant-chat-search-result-item h4 {
    margin: 0 0 8px 0;
    font-size: 16px;
    color: #7c3aed;
    font-weight: 600;
    padding-right: 20px;
}

.lab-assistant-chat-result-lab {
    margin: 0 0 8px 0;
    font-size: 13px;
    color: #555;
    font-weight: 500;
    display: flex;
    align-items: center;
    background-color: rgba(124, 58, 237, 0.05);
    padding: 4px 8px 4px 22px;
    border-radius: 4px;
    width: fit-content;
    position: relative;
}

.lab-assistant-chat-result-lab::before {
    content: attr(data-emoji);
    position: absolute;
    left: 4px;
    font-size: 14px;
    text-decoration: none !important;
}

/* Fallback emoji for lab results without data-emoji */
.lab-assistant-chat-result-lab:not([data-emoji])::before {
    content: "📍";
}

.lab-assistant-chat-result-relevance {
    margin: 0 0 8px 0;
    font-size: 13px;
    color: #555;
    font-style: italic;
    line-height: 1.4;
}

.lab-assistant-chat-result-highlights {
    margin: 0;
    font-size: 13px;
    color: #444;
    line-height: 1.5;
}

/* Styling for matched equipment information */
.lab-assistant-chat-result-equipment {
    margin: 8px 0;
    font-size: 13px;
    color: #444;
    line-height: 1.5;
    background-color: rgba(124, 58, 237, 0.05);
    padding: 6px 10px 6px 22px;
    border-radius: 4px;
    border-left: 3px solid rgba(124, 58, 237, 0.3);
    position: relative;
}

.lab-assistant-chat-result-equipment::before {
    content: attr(data-emoji);
    position: absolute;
    left: 4px;
    top: 6px;
    font-size: 14px;
    text-decoration: none !important;
}

/* Fallback emoji for equipment without data-emoji */
.lab-assistant-chat-result-equipment:not([data-emoji])::before {
    content: "⚙️";
}

.lab-assistant-chat-result-equipment strong {
    color: #333;
    font-weight: 600;
    display: block;
    margin-bottom: 4px;
}

.lab-assistant-chat-result-equipment-desc {
    font-style: italic;
    color: #555;
    margin-top: 4px;
}

/* Image upload styling */
.lab-assistant-chat-image-btn {
    background-color: transparent;
    border: none;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    padding: 8px;
    border-radius: 8px;
    margin-right: 4px;
}

.lab-assistant-chat-image-btn:hover:not(:disabled) {
    background-color: rgba(124, 58, 237, 0.1);
    color: #7c3aed;
}

.lab-assistant-chat-image-btn:disabled {
    color: #ccc;
    cursor: not-allowed;
}

/* Image preview container styling */
.lab-assistant-chat-image-preview-container {
    position: relative;
    margin-bottom: 12px;
    border-radius: 8px;
    overflow: hidden;
    width: 120px;
    height: 80px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-shrink: 0; /* Prevent image from affecting the layout flow */
    float: left; /* Float the image to avoid expanding the container height */
    clear: both;
}

.lab-assistant-chat-image-preview {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.lab-assistant-chat-remove-image-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    font-size: 18px;
}

.lab-assistant-chat-remove-image-btn:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Uploaded image in message styling */
.lab-assistant-chat-message-image {
    max-width: 300px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    order: -1; /* Position above the message content */
    align-self: flex-start; /* Align to the start of the flex container */
}

.lab-assistant-chat-user-message .lab-assistant-chat-message-image {
    align-self: flex-end;
    margin-left: auto;
}

.lab-assistant-chat-uploaded-image {
    max-width: 100%;
    max-height: 250px;
    display: block;
    border-radius: 8px;
    object-fit: contain;
}

/* Reasoning trace styling */
.lab-assistant-chat-reasoning {
    margin-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Special styling for reasoning content during generation */
.lab-assistant-chat-reasoning-generating {
    margin-bottom: 16px;
    background-color: #f8f9ff;
    border: 1px solid rgba(124, 58, 237, 0.2);
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 2px 6px rgba(124, 58, 237, 0.1);
}

.lab-assistant-chat-reasoning-generating .lab-assistant-chat-reasoning-header {
    color: #7c3aed;
    font-weight: 600;
    padding: 4px 0 4px 18px;
    position: relative;
}

.lab-assistant-chat-reasoning-generating
    .lab-assistant-chat-reasoning-header
    span::before {
    content: "🧠";
    position: absolute;
    left: 0;
    font-size: 14px;
}

.lab-assistant-chat-reasoning-generating .lab-assistant-chat-reasoning-content {
    background-color: #ffffff;
    border-left: 3px solid #7c3aed;
    max-height: none;
}

.lab-assistant-chat-reasoning-header {
    padding: 8px 0;
    font-size: 0.85rem;
    color: #666;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 18px;
}

.lab-assistant-chat-reasoning-header:hover {
    color: #7c3aed;
}

.lab-assistant-chat-reasoning-header span::before {
    content: "🧠";
    position: absolute;
    left: 0;
    font-size: 14px;
}

.lab-assistant-chat-reasoning-content {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    font-size: 0.9rem;
    color: #555;
    white-space: pre-wrap;
    margin-bottom: 8px;
    max-height: 300px;
    overflow-y: auto;
    font-family: monospace;
}

/* Entity links styling */
.lab-assistant-chat-entity-link {
    color: #7c3aed;
    text-decoration: none;
    font-weight: 500;
    position: relative;
    padding: 2px 4px 2px 18px;
    border-radius: 4px;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
}

.lab-assistant-chat-entity-link::before {
    content: attr(data-emoji);
    position: absolute;
    left: 2px;
    font-size: 12px;
    opacity: 0.7;
    text-decoration: none !important;
}

.lab-assistant-chat-entity-link:hover {
    background-color: rgba(124, 58, 237, 0.1);
    text-decoration: underline;
    color: #6d28d9;
}

.lab-assistant-chat-entity-link:hover::before {
    opacity: 1;
    text-decoration: none !important;
}

/* Table styling for chat messages */
.lab-assistant-chat-message-text table {
    border-collapse: collapse;
    width: 100%;
    margin: 16px 0;
    font-size: 14px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.lab-assistant-chat-message-text table thead {
    background-color: #f0f1ff;
    color: #333;
}

.lab-assistant-chat-message-text table th {
    text-align: left;
    padding: 12px 16px;
    font-weight: 600;
    border-bottom: 2px solid #e0e1ff;
}

.lab-assistant-chat-message-text table td {
    padding: 10px 16px;
    border-bottom: 1px solid #eaeaea;
    vertical-align: top;
}

.lab-assistant-chat-message-text table tr:last-child td {
    border-bottom: none;
}

.lab-assistant-chat-message-text table tr:nth-child(even) {
    background-color: #f9f9ff;
}

.lab-assistant-chat-message-text table tr:hover {
    background-color: #f0f3ff;
}

/* Add emoji styling for labs in tables */
.lab-assistant-chat-message-text table td a[href*="/lab/"] {
    position: relative;
    padding-left: 20px;
}

.lab-assistant-chat-message-text table td a[href*="/lab/"]::before {
    content: "📍";
    position: absolute;
    left: 0;
}

/* Make tables responsive on mobile */
@media (max-width: 768px) {
    .lab-assistant-chat-message-text table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
    }

    .lab-assistant-chat-message-text table th,
    .lab-assistant-chat-message-text table td {
        padding: 8px 12px;
    }
}

/* Improve the AI message that contains search results */
.lab-assistant-chat-ai-message.lab-assistant-chat-search-results-message {
    max-width: 90%;
    padding: 16px;
}

/* Media queries for responsive design */
@media (max-width: 1200px) {
    .lab-assistant-chat-messages-wrapper,
    .lab-assistant-chat-input-container form {
        width: 80%;
    }
}

@media (max-width: 992px) {
    .lab-assistant-chat-messages-wrapper,
    .lab-assistant-chat-input-container form {
        width: 85%;
    }
}

@media (max-width: 768px) {
    .lab-assistant-chat-container {
        height: calc(100vh - 56px); /* Account for smaller navbar on mobile */
    }

    .lab-assistant-chat-sidebar {
        display: none; /* Hide sidebar on mobile */
    }

    .lab-assistant-chat-input-container {
        left: 0; /* Start from left edge when sidebar is hidden */
        padding: 12px 0;
    }

    .lab-assistant-chat-main {
        padding-bottom: 0;
        width: 100%;
    }

    .lab-assistant-chat-messages-container {
        padding: 16px 0;
        padding-bottom: 120px; /* Reduced from 320px */
    }

    .lab-assistant-chat-scroll-btn {
        bottom: 130px; /* Reduced from 240px */
        right: 16px;
        width: 36px;
        height: 36px;
    }

    .lab-assistant-chat-quick-actions {
        gap: 12px;
    }

    .lab-assistant-chat-action-btn {
        width: 80px;
        padding: 12px;
    }

    .lab-assistant-chat-search-result-item {
        padding: 12px;
    }

    .lab-assistant-chat-search-result-item h4 {
        font-size: 14px;
    }

    .lab-assistant-chat-message-image {
        max-width: 90%;
    }
}

@media (max-width: 480px) {
    .lab-assistant-chat-welcome h1 {
        font-size: 24px;
    }

    .lab-assistant-chat-message-content {
        max-width: 90%;
    }

    .lab-assistant-chat-action-btn {
        width: 70px;
        padding: 8px;
    }

    .lab-assistant-chat-messages-container {
        padding-left: 16px;
        padding-right: 16px;
    }
}

/* Delete confirmation modal custom styling */
.modal-content .modal-cancel-button {
    background-color: #f5f5f5;
    color: #666;
    border: 1px solid #ddd;
    transition: all 0.2s ease;
    font-weight: 400;
}

.modal-content .modal-cancel-button:hover {
    background-color: #e0e0e0;
}

.modal-content .modal-submit-button {
    background-color: #d32f2f;
    transition: all 0.2s ease;
}

.modal-content .modal-submit-button:hover {
    background-color: #b71c1c;
}

/* Custom delete modal styling */
.modal-content {
    max-width: 500px;
    min-height: auto;
}

.modal-content .modal-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

/* Delete confirmation wrapper */
.delete-confirmation-wrapper .modal {
    animation: fadeIn 0.2s ease forwards;
}

.delete-confirmation-wrapper .modal-content {
    animation: scaleIn 0.3s ease forwards;
    transform-origin: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes scaleIn {
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

/* Inline editing styles */
.lab-assistant-chat-thread-edit {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.lab-assistant-chat-thread-edit-input {
    flex: 1;
    width: 100%;
    padding: 2px 5px;
    border: 1px solid #7c3aed;
    border-radius: 4px;
    font-size: 13px;
    background-color: white;
    outline: none;
    min-width: 0;
}

.lab-assistant-chat-thread-edit-actions {
    display: flex;
    align-items: center;
    margin-left: 4px;
}

.lab-assistant-chat-thread-edit-btn {
    background: none;
    border: none;
    padding: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.lab-assistant-chat-thread-edit-btn.save {
    color: #10b981;
}

.lab-assistant-chat-thread-edit-btn.save:hover {
    background-color: rgba(16, 185, 129, 0.1);
}

.lab-assistant-chat-thread-edit-btn.cancel {
    color: #ef4444;
}

.lab-assistant-chat-thread-edit-btn.cancel:hover {
    background-color: rgba(239, 68, 68, 0.1);
}

/* Edit button for messages */
.lab-assistant-chat-edit-message-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent;
    border: none;
    color: #ffffff;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s ease, background-color 0.2s ease;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.lab-assistant-chat-user-message:hover .lab-assistant-chat-edit-message-btn {
    opacity: 0.7;
}

.lab-assistant-chat-edit-message-btn:hover {
    opacity: 1 !important;
    background-color: rgba(255, 255, 255, 0.2);
}

/* Editing mode styles */
.lab-assistant-chat-input-wrapper.is-editing {
    border-color: #7c3aed;
    background-color: #f9f7ff;
    box-shadow: 0 0 0 1px rgba(124, 58, 237, 0.5),
        0 2px 8px rgba(124, 58, 237, 0.15);
}

.lab-assistant-chat-editing-indicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ede9fe;
    color: #7c3aed;
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 4px;
    margin-bottom: 8px;
    border: 1px solid rgba(124, 58, 237, 0.3);
}

.lab-assistant-chat-editing-indicator span {
    font-weight: 500;
}

.lab-assistant-chat-cancel-edit-btn {
    background: none;
    border: none;
    color: #7c3aed;
    cursor: pointer;
    padding: 4px;
    margin-left: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.lab-assistant-chat-cancel-edit-btn:hover {
    background-color: rgba(124, 58, 237, 0.1);
}

.lab-assistant-chat-user-message {
    position: relative; /* Ensure position relative for absolute positioning of edit button */
}
