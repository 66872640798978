/* Chatbox Styles */
.lab-assistant-chatbot-add-button {
    position: fixed;
    bottom: 28px;
    right: 28px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(124, 58, 237, 0.3);
    transition: all 0.2s ease-in-out;
    z-index: 1000;
}

.lab-assistant-chatbot-add-button:hover {
    background: #6d28d9;
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(124, 58, 237, 0.4);
}

.lab-assistant-chatbot-add-button-active {
    background: #6d28d9;
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(124, 58, 237, 0.5);
}

.lab-assistant-chatbot-chatbox {
    position: fixed;
    bottom: 100px;
    right: 30px;
    width: 400px;
    height: 550px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: lab-assistant-chatbot-slide-up 0.3s ease-out forwards;
    z-index: 1000;
    overscroll-behavior: contain; /* Prevents scroll chaining */
    /* Isolate scrolling within the chatbox and its children */
    isolation: isolate;
}

.lab-assistant-chatbot-chatbox-maximized {
    width: calc(100% - 20px);
    height: calc(90% - 30px);
    bottom: 0;
    right: 10px;
    left: 10px;
    top: 30px;
    margin: auto;
    max-width: none;
    border-radius: 8px;
}

@keyframes lab-assistant-chatbot-slide-up {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.lab-assistant-chatbot-chatbox-header {
    padding: 12px 16px;
    background: var(--primary-color, #5270ff);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.lab-assistant-chatbot-chatbox-title {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-weight: 600;
}

.lab-assistant-chatbot-chatbox-title span {
    display: flex;
    align-items: center;
    gap: 4px;
}

.lab-assistant-chatbot-chatbox-title svg {
    color: white;
}

.lab-assistant-chatbot-chatbox-controls {
    display: flex;
    gap: 8px;
}

.lab-assistant-chatbot-chatbox-maximize,
.lab-assistant-chatbot-chatbox-close {
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s;
}

.lab-assistant-chatbot-chatbox-maximize:hover,
.lab-assistant-chatbot-chatbox-close:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.lab-assistant-chatbot-chatbox-maximize svg,
.lab-assistant-chatbot-chatbox-close svg {
    font-size: 20px;
}

.lab-assistant-chatbot-chatbox-messages {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #f9f9f9;
    overscroll-behavior: contain; /* Prevents scroll chaining */
    /* Enhance scroll containment */
    touch-action: pan-y;
}

.lab-assistant-chatbot-chatbox-maximized .lab-assistant-chatbot-chat-message {
    max-width: 60%;
}

.lab-assistant-chatbot-chat-message {
    padding: 12px 16px;
    border-radius: 12px;
    max-width: 85%;
    line-height: 1.5;
    position: relative;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.lab-assistant-chatbot-user-message {
    align-self: flex-end;
    background-color: var(--primary-color, #5270ff);
    color: white;
    border-bottom-right-radius: 4px;
}

.lab-assistant-chatbot-ai-message {
    align-self: flex-start;
    background-color: #f0f0f0;
    color: #333;
    border-bottom-left-radius: 4px;
}

.lab-assistant-chatbot-chatbox-input {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
}

.lab-assistant-chatbot-input-container {
    display: flex;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 10px;
    flex-direction: column;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    transition: height 0.1s ease;
}

.lab-assistant-chatbot-message-area {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    align-items: flex-end;
}

.lab-assistant-chatbot-search-toggles {
    display: flex;
    gap: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 8px;
    margin-top: 2px;
    justify-content: flex-start;
}

.lab-assistant-chatbot-search-toggle {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 13px;
    background-color: transparent;
    color: #777;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
    flex: 0 0 auto;
    justify-content: center;
}

.lab-assistant-chatbot-search-toggle svg {
    margin-right: 5px;
    font-size: 16px;
}

.lab-assistant-chatbot-search-toggle:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #555;
}

.lab-assistant-chatbot-search-toggle.active {
    background-color: rgba(82, 112, 255, 0.1);
    color: var(--primary-color, #5270ff);
}

.lab-assistant-chatbot-search-toggle.active svg {
    color: var(--primary-color, #5270ff);
}

.lab-assistant-chatbot-chatbox-input input {
    flex: 1;
    border: none;
    padding: 10px;
    font-size: 14px;
    background-color: transparent;
    outline: none;
    color: #333;
}

.lab-assistant-chatbot-textarea {
    flex: 1;
    border: none;
    padding: 10px;
    font-size: 14px;
    background-color: transparent;
    outline: none;
    color: #333;
    resize: none;
    overflow-y: auto;
    min-height: 20px;
    height: 20px;
    max-height: 150px;
    line-height: 1.5;
    transition: height 0.1s ease;
}

.lab-assistant-chatbot-chatbox-input input::placeholder,
.lab-assistant-chatbot-textarea::placeholder {
    color: #999;
}

.lab-assistant-chatbot-chatbox-input button[type="submit"] {
    background-color: transparent;
    border: none;
    color: var(--primary-color, #5270ff);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    padding: 8px;
    border-radius: 8px;
}

.lab-assistant-chatbot-chatbox-input
    button[type="submit"]:hover:not(:disabled) {
    background-color: rgba(82, 112, 255, 0.1);
}

.lab-assistant-chatbot-chatbox-input button[type="submit"]:disabled {
    color: #ccc;
    cursor: not-allowed;
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
    .lab-assistant-chatbot-chatbox {
        width: calc(100% - 32px);
        height: 77vh;
        bottom: 80px;
        right: 16px;
        left: 16px;
    }

    .lab-assistant-chatbot-chatbox-maximized {
        width: 100%;
        height: 100%;
        max-width: none;
        border-radius: 0;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        margin: 0;
    }

    .lab-assistant-chatbot-chatbox-maximized
        .lab-assistant-chatbot-chat-message {
        max-width: 85%;
    }

    .lab-assistant-chatbot-chatbox-maximized
        .lab-assistant-chatbot-chat-input-container {
        padding: 10px;
    }

    .lab-assistant-chatbot-textarea {
        max-height: 120px; /* Slightly smaller max height on mobile */
    }
}

/* Media query for extra small screens */
@media screen and (max-width: 480px) {
    .lab-assistant-chatbot-chatbox-maximized
        .lab-assistant-chatbot-chat-message {
        max-width: 90%;
    }

    .lab-assistant-chatbot-search-toggle {
        padding: 4px 8px;
        font-size: 12px;
    }

    .lab-assistant-chatbot-search-toggle svg {
        font-size: 14px;
    }
}

/* Chat typing indicator */
.lab-assistant-chatbot-typing-indicator {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    min-height: 20px;
    background-color: #f0f0f0;
    margin-bottom: 10px;
}

.lab-assistant-chatbot-typing-text {
    font-size: 14px;
    color: #555;
    margin-right: 8px;
    font-weight: 500;
}

.lab-assistant-chatbot-typing-indicator .lab-assistant-chatbot-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #aaa;
    animation: typing-animation 1.4s infinite ease-in-out;
}

.lab-assistant-chatbot-typing-indicator
    .lab-assistant-chatbot-dot:nth-child(1) {
    animation-delay: 0s;
}

.lab-assistant-chatbot-typing-indicator
    .lab-assistant-chatbot-dot:nth-child(2) {
    animation-delay: 0.2s;
}

.lab-assistant-chatbot-typing-indicator
    .lab-assistant-chatbot-dot:nth-child(3) {
    animation-delay: 0.4s;
    margin-right: 0;
}

@keyframes typing-animation {
    0%,
    60%,
    100% {
        transform: translateY(0);
        opacity: 0.6;
    }
    30% {
        transform: translateY(-4px);
        opacity: 1;
    }
}

/* Error message styling */
.lab-assistant-chatbot-error-message {
    background-color: rgba(255, 0, 0, 0.1);
    border-left: 3px solid #ff5252;
}

/* Disabled button styling */
.lab-assistant-chatbot-chatbox-input button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Message text styling */
.lab-assistant-chatbot-message-text {
    margin-bottom: 8px;
    white-space: pre-wrap;
    line-height: 1.5;
    font-size: 14px;
    color: #333;
}

.lab-assistant-chatbot-user-message .lab-assistant-chatbot-message-text {
    color: white;
}

/* Search results styling */
.lab-assistant-chatbot-search-results {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 8px;
}

.lab-assistant-chatbot-search-results::before {
    content: "Search Results";
    display: block;
    font-weight: 600;
    margin-bottom: 12px;
    color: #555;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.lab-assistant-chatbot-search-result-item {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    position: relative;
}

.lab-assistant-chatbot-search-result-item:hover {
    background-color: #f0f7ff;
    border-color: var(--primary-color);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.lab-assistant-chatbot-search-result-item:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lab-assistant-chatbot-search-result-item::after {
    content: "→";
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color);
    font-size: 18px;
    opacity: 0.7;
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.lab-assistant-chatbot-search-result-item:hover::after {
    opacity: 1;
    transform: translate(3px, -50%);
}

.lab-assistant-chatbot-search-result-item h4 {
    margin: 0 0 8px 0;
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
    padding-right: 20px;
}

.lab-assistant-chatbot-result-lab {
    margin: 0 0 8px 0;
    font-size: 13px;
    color: #555;
    font-weight: 500;
    display: flex;
    align-items: center;
    background-color: rgba(82, 112, 255, 0.05);
    padding: 4px 8px 4px 22px;
    border-radius: 4px;
    width: fit-content;
    position: relative;
}

.lab-assistant-chatbot-result-lab::before {
    content: attr(data-emoji);
    position: absolute;
    left: 4px;
    font-size: 14px;
    text-decoration: none !important;
}

/* Fallback emoji for lab results without data-emoji */
.lab-assistant-chatbot-result-lab:not([data-emoji])::before {
    content: "📍";
}

.lab-assistant-chatbot-result-relevance {
    margin: 0 0 8px 0;
    font-size: 13px;
    color: #555;
    font-style: italic;
    line-height: 1.4;
}

.lab-assistant-chatbot-result-highlights {
    margin: 0;
    font-size: 13px;
    color: #444;
    line-height: 1.5;
}

/* Styling for matched equipment information */
.lab-assistant-chatbot-result-equipment {
    margin: 8px 0;
    font-size: 13px;
    color: #444;
    line-height: 1.5;
    background-color: rgba(82, 112, 255, 0.05);
    padding: 6px 10px 6px 22px;
    border-radius: 4px;
    border-left: 3px solid rgba(82, 112, 255, 0.3);
    position: relative;
}

.lab-assistant-chatbot-result-equipment::before {
    content: attr(data-emoji);
    position: absolute;
    left: 4px;
    top: 6px;
    font-size: 14px;
    text-decoration: none !important;
}

/* Fallback emoji for equipment without data-emoji */
.lab-assistant-chatbot-result-equipment:not([data-emoji])::before {
    content: "⚙️";
}

.lab-assistant-chatbot-result-equipment strong {
    color: #333;
    font-weight: 600;
    display: block;
    margin-bottom: 4px;
}

.lab-assistant-chatbot-result-equipment-desc {
    font-style: italic;
    color: #555;
    margin-top: 4px;
}

/* Preserve markdown styling */
.lab-assistant-chatbot-message-text strong {
    font-weight: 600;
}

.lab-assistant-chatbot-message-text em {
    font-style: italic;
}

.lab-assistant-chatbot-message-text ul,
.lab-assistant-chatbot-message-text ol {
    margin: 12px 0;
    padding-left: 24px;
    list-style-position: outside;
}

.lab-assistant-chatbot-message-text ul {
    list-style-type: disc;
}

.lab-assistant-chatbot-message-text ol {
    list-style-type: decimal;
}

.lab-assistant-chatbot-message-text ul li,
.lab-assistant-chatbot-message-text ol li {
    margin-bottom: 8px;
    padding-left: 4px;
    line-height: 1.5;
}

.lab-assistant-chatbot-message-text p {
    margin-bottom: 12px;
    line-height: 1.6;
}

.lab-assistant-chatbot-message-text h1,
.lab-assistant-chatbot-message-text h2,
.lab-assistant-chatbot-message-text h3,
.lab-assistant-chatbot-message-text h4,
.lab-assistant-chatbot-message-text h5,
.lab-assistant-chatbot-message-text h6 {
    margin-top: 20px;
    margin-bottom: 12px;
    font-weight: 600;
    line-height: 1.3;
    color: #333;
}

.lab-assistant-chatbot-message-text h1 {
    font-size: 22px;
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
}

.lab-assistant-chatbot-message-text h2 {
    font-size: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 6px;
}

.lab-assistant-chatbot-message-text h3 {
    font-size: 18px;
}

.lab-assistant-chatbot-message-text h4 {
    font-size: 16px;
}

.lab-assistant-chatbot-message-text h5,
.lab-assistant-chatbot-message-text h6 {
    font-size: 15px;
}

.lab-assistant-chatbot-message-text code {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 2px 6px;
    border-radius: 4px;
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
    font-size: 13px;
    color: #333;
    word-break: break-word;
}

.lab-assistant-chatbot-message-text pre {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 16px;
    border-radius: 6px;
    overflow-x: auto;
    margin: 16px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.lab-assistant-chatbot-message-text pre code {
    background-color: transparent;
    padding: 0;
    font-size: 13px;
    line-height: 1.6;
    display: block;
    white-space: pre;
    color: #333;
    border-radius: 0;
}

.lab-assistant-chatbot-message-text blockquote {
    border-left: 4px solid var(--primary-color, #5270ff);
    padding: 8px 16px;
    margin: 16px 0;
    background-color: rgba(82, 112, 255, 0.05);
    color: #444;
    font-style: italic;
    border-radius: 0 4px 4px 0;
}

.lab-assistant-chatbot-message-text a {
    color: var(--primary-color, #5270ff);
    text-decoration: underline;
    transition: color 0.2s ease;
}

.lab-assistant-chatbot-message-text a:hover {
    color: #6d28d9;
}

.lab-assistant-chatbot-message-text table {
    border-collapse: collapse;
    width: 100%;
    margin: 16px 0;
    font-size: 14px;
    overflow-x: auto;
    display: block;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.lab-assistant-chatbot-message-text th,
.lab-assistant-chatbot-message-text td {
    border: 1px solid #e0e0e0;
    padding: 8px 12px;
    text-align: left;
    vertical-align: top;
    white-space: normal;
    word-break: break-word;
}

.lab-assistant-chatbot-message-text th {
    background-color: rgba(82, 112, 255, 0.1);
    font-weight: 600;
    color: #333;
    position: sticky;
    top: 0;
}

.lab-assistant-chatbot-message-text tr:nth-child(even) {
    background-color: #f9f9f9;
}

.lab-assistant-chatbot-message-text tr:hover {
    background-color: #f0f7ff;
}

/* Handle tables inside blocks */
.lab-assistant-chatbot-message-text blockquote table,
.lab-assistant-chatbot-message-text li table {
    width: calc(100% - 8px);
    margin: 8px 0;
}

/* Ensure there's proper spacing around tables */
.lab-assistant-chatbot-message-text p + table,
.lab-assistant-chatbot-message-text table + p {
    margin-top: 16px;
}

/* Scientific notation styles */
.lab-assistant-chatbot-message-text sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
}

.lab-assistant-chatbot-message-text sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    bottom: -0.25em;
}

/* Fix line breaks in messages */
.lab-assistant-chatbot-message-text br {
    display: block;
    content: "";
    margin-top: 8px;
}

/* Ensure proper spacing between paragraphs */
.lab-assistant-chatbot-message-text p + p {
    margin-top: 12px;
}

/* Improve the AI message that contains search results */
.lab-assistant-chatbot-ai-message.lab-assistant-chatbot-chat-message:has(
        .lab-assistant-chatbot-search-results
    ) {
    max-width: 90%;
    padding: 16px;
}

/* Ensure the AI message with search results has proper spacing */
.lab-assistant-chatbot-ai-message.lab-assistant-chatbot-chat-message.isSearchResults {
    padding: 16px;
    background-color: #f8f8f8;
}

/* Clickable title styling */
.lab-assistant-chatbot-clickable-title {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s ease;
    border-bottom: 1px dashed var(--primary-color);
    display: inline-block;
    margin-bottom: 2px;
    position: relative;
    background-color: rgba(124, 58, 237, 0.05);
    padding: 2px 6px;
    border-radius: 4px;
}

.lab-assistant-chatbot-clickable-title:hover {
    color: #6d28d9;
    border-bottom: 1px solid #6d28d9;
    text-decoration: none;
    background-color: rgba(124, 58, 237, 0.1);
    box-shadow: 0 1px 3px rgba(124, 58, 237, 0.2);
}

/* Search description styling */
.lab-assistant-chatbot-search-description {
    margin: 8px 0 8px 24px;
    padding: 6px 0;
    color: #444;
    line-height: 1.5;
    position: relative;
    font-size: 14px;
}

.lab-assistant-chatbot-search-description:before {
    content: "•";
    position: absolute;
    left: -16px;
    color: var(--primary-color);
    font-weight: bold;
}

.lab-assistant-chatbot-search-description strong {
    color: #333;
    font-weight: 600;
}

.lab-assistant-chatbot-search-description em {
    font-style: italic;
}

.lab-assistant-chatbot-search-relevance {
    margin: 4px 0 4px 24px;
    padding: 4px 0;
    color: #555;
    line-height: 1.4;
    position: relative;
    font-size: 13px;
    font-style: italic;
}

.lab-assistant-chatbot-search-relevance:before {
    content: "Relevance: ";
    font-weight: 600;
    color: #444;
    font-style: normal;
}

.lab-assistant-chatbot-search-highlights {
    margin: 4px 0 12px 24px;
    padding: 4px 0;
    color: #444;
    line-height: 1.5;
    position: relative;
    font-size: 13px;
}

.lab-assistant-chatbot-search-highlights:before {
    content: "Highlights: ";
    font-weight: 600;
    color: #444;
}

/* Ensure proper spacing between search results */
.lab-assistant-chatbot-clickable-title {
    margin-bottom: 6px;
    display: inline-block;
}

/* Search query list styling */
.lab-assistant-chatbot-search-query-list {
    list-style: none;
    padding: 0;
    margin: 4px 0 8px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.lab-assistant-chatbot-search-query-list li {
    background-color: #f0f0f0;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    padding: 4px 12px;
    font-size: 13px;
    color: #444;
    display: inline-block;
    transition: all 0.2s ease;
}

.lab-assistant-chatbot-search-query-list li:hover {
    background-color: #e8e8ff;
    border-color: var(--primary-color);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Ensure proper spacing for search query section */
.lab-assistant-chatbot-ai-message
    .lab-assistant-chatbot-message-text
    strong
    + ul.lab-assistant-chatbot-search-query-list {
    margin-top: 6px;
    margin-bottom: 6px;
}

/* Reasoning content styles */
.lab-assistant-chatbot-reasoning {
    margin-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Special styling for reasoning content during generation */
.lab-assistant-chatbot-reasoning-generating {
    margin-bottom: 16px;
    background-color: #f8f9ff;
    border: 1px solid rgba(82, 112, 255, 0.2);
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 2px 6px rgba(82, 112, 255, 0.1);
}

.lab-assistant-chatbot-reasoning-generating
    .lab-assistant-chatbot-reasoning-header {
    color: #5270ff;
    font-weight: 600;
    padding: 4px 0 4px 18px;
    position: relative;
}

.lab-assistant-chatbot-reasoning-generating
    .lab-assistant-chatbot-reasoning-header
    span::before {
    content: "🧠";
    position: absolute;
    left: 0;
    font-size: 14px;
}

.lab-assistant-chatbot-reasoning-generating
    .lab-assistant-chatbot-reasoning-content {
    background-color: #ffffff;
    border-left: 3px solid #5270ff;
    max-height: none;
}

.lab-assistant-chatbot-reasoning-header {
    padding: 8px 0;
    font-size: 0.85rem;
    color: #666;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 18px;
}

.lab-assistant-chatbot-reasoning-header:hover {
    color: #1976d2;
}

.lab-assistant-chatbot-reasoning-header span::before {
    content: "🧠";
    position: absolute;
    left: 0;
    font-size: 14px;
}

.lab-assistant-chatbot-reasoning-content {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    font-size: 0.9rem;
    color: #555;
    white-space: pre-wrap;
    margin-bottom: 8px;
    max-height: 300px;
    overflow-y: auto;
    font-family: monospace;
}

/* Auto-scroll paused indicator */
.lab-assistant-chatbot-scroll-paused {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 8px 12px 8px 26px;
    border-radius: 20px;
    font-size: 12px;
    z-index: 10;
    display: flex;
    align-items: center;
    animation: fade-in 0.3s ease-in-out;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.lab-assistant-chatbot-scroll-paused::before {
    content: "⏸️";
    position: absolute;
    left: 8px;
    font-size: 14px;
}

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Entity links styling */
.lab-assistant-chatbot-entity-link {
    color: var(--primary-color, #5270ff);
    text-decoration: none;
    font-weight: 500;
    position: relative;
    padding: 2px 4px 2px 18px;
    border-radius: 4px;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
}

.lab-assistant-chatbot-entity-link::before {
    content: attr(data-emoji);
    position: absolute;
    left: 2px;
    font-size: 12px;
    opacity: 0.7;
    text-decoration: none !important;
}

.lab-assistant-chatbot-entity-link:hover {
    background-color: rgba(82, 112, 255, 0.1);
    text-decoration: underline;
    color: #4361ee;
}

.lab-assistant-chatbot-entity-link:hover::before {
    opacity: 1;
    text-decoration: none !important;
}

/* Fallback emojis for backward compatibility */
.lab-assistant-chatbot-entity-link.lab-link:not([data-emoji])::before {
    content: "🔬";
}

.lab-assistant-chatbot-entity-link.listing-link:not([data-emoji])::before {
    content: "📦";
}

.lab-assistant-chatbot-entity-link.equipment-link:not([data-emoji])::before {
    content: "⚙️";
}

.lab-assistant-chatbot-entity-link.user-link:not([data-emoji])::before {
    content: "👤";
}

/* User mention styling for lab members */
.lab-assistant-chatbot-user-mention {
    color: var(--primary-color, #5270ff);
    font-weight: 500;
    position: relative;
    padding: 2px 4px 2px 18px;
    border-radius: 4px;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.lab-assistant-chatbot-user-mention::before {
    content: attr(data-emoji);
    position: absolute;
    left: 2px;
    font-size: 12px;
    opacity: 0.7;
    text-decoration: none !important;
}

/* Fallback emoji for user mentions without data-emoji */
.lab-assistant-chatbot-user-mention:not([data-emoji])::before {
    content: "👤";
}

.lab-assistant-chatbot-user-mention:hover {
    background-color: rgba(82, 112, 255, 0.1);
    color: #4361ee;
    text-decoration: underline;
}

.lab-assistant-chatbot-user-mention:hover::before {
    opacity: 1;
    text-decoration: none !important;
}

.lab-assistant-chatbot-queries-count {
    font-size: 12px;
    color: #ccc;
    margin-left: 8px;
    font-weight: normal;
}

/* Image upload button styling */
.lab-assistant-chatbot-image-btn {
    background-color: transparent;
    border: none;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    padding: 8px;
    border-radius: 8px;
    margin-right: 4px;
}

.lab-assistant-chatbot-image-btn:hover:not(:disabled) {
    background-color: rgba(82, 112, 255, 0.1);
    color: var(--primary-color, #5270ff);
}

.lab-assistant-chatbot-image-btn:disabled {
    color: #ccc;
    cursor: not-allowed;
}

/* Image preview container styling */
.lab-assistant-chatbot-image-preview-container {
    position: relative;
    margin-bottom: 8px;
    border-radius: 8px;
    overflow: hidden;
    max-width: 200px;
    max-height: 150px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lab-assistant-chatbot-image-preview {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.lab-assistant-chatbot-remove-image-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    font-size: 18px;
}

.lab-assistant-chatbot-remove-image-btn:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Uploaded image in message styling */
.lab-assistant-chatbot-message-image {
    margin-top: 8px;
    margin-bottom: 8px;
    max-width: 80%;
    border-radius: 8px;
    overflow: hidden;
}

.lab-assistant-chatbot-user-message .lab-assistant-chatbot-message-image {
    align-self: flex-end;
    margin-left: auto;
}

.lab-assistant-chatbot-uploaded-image {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
}
